import CellBoxSelect from '../select/CellBoxSelect';

const Gender = props => {
  const gender = ['남', '여'];
  const { idx } = props;
  const thisGenderName = `gender_${idx}`;
  return <CellBoxSelect name={thisGenderName} optionList={gender} />;
};

export default Gender;
