export const KeyType = ['applyName', 'phoneNumber', 'email'];

interface KeyData {
  [key: string]: any;
  applyName?: string;
  phoneNumber?: string;
  email?: string;
}

export const getHasKeyType = (data: KeyData) => {
  return KeyType.some(key => data.hasOwnProperty(key));
};

export const getEmailInfo = (data: KeyData) => {
  if (data.hasOwnProperty('email')) {
    return data.email;
  } else {
    return '';
  }
};
