import { useFormContext } from 'react-hook-form';

const ApplyCheckEmail = () => {
  const { register } = useFormContext();

  return (
    <div className="form_cell">
      <div className="form_cell_header">
        <p className="title active">이메일</p>
      </div>
      <div className="form_cell_cont">
        <div className="cell_box">
          <div className="cell_txt1">
            <input
              type="text"
              {...register('email')}
              placeholder={`이메일을 입력바랍니다.`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplyCheckEmail;
