import { useFormContext } from 'react-hook-form';
import { useSectionContext } from '../../../../contexts/SectionContext';
import { useState } from 'react';
import classNames from 'classnames';

const Input = ({
  className,
  children,
  html,
  placeholder,
  descriptive,
  ...props
}) => {
  const {
    register,
    getValues,
    formState: { errors },
  } = useFormContext();
  const { required, disabled, isStatic } = useSectionContext();
  const thisName = 'input' + '_' + props.idx;

  const [count, setCount] = useState(0);

  const changeTextArea = e => {
    const { value } = e.target;

    // 최대 1000자 제한
    if (value.length > 1000) {
      e.target.value = value.slice(0, 1000);
      setCount(1000);
    } else {
      setCount(value.length);
    }
  };

  const changeInput = e => {
    const { value } = e.target;

    // 최대 100자 제한
    if (value.length > 100) {
      e.target.value = value.slice(0, 100);
      setCount(100);
    } else {
      setCount(value.length);
    }
  };

  if (isStatic) {
    if (descriptive) {
      return <p className="result scroll">{getValues(thisName)}</p>;
    } else {
      return <p className="result">{getValues(thisName)}</p>;
    }
  }

  const [isFocus, setIsFocus] = useState(false);

  return (
    <div className={'cell_box'}>
      {descriptive ? (
        <>
          <div
            className={classNames({
              cell_textarea1: true,
              disabled: disabled,
              error: errors[thisName],
              focus: isFocus,
            })}
          >
            <textarea
              placeholder="참여자가 답변을 입력하는 칸입니다."
              {...register(thisName, {
                required: required,
                onChange: changeTextArea,
                onBlur: () => setIsFocus(false),
              })}
              onFocus={() => setIsFocus(true)}
            />
          </div>
          <p className="count1">
            <strong>{count}</strong> / 최대 1000자
          </p>
        </>
      ) : (
        <>
          <div
            className={classNames({
              cell_txt1: true,
              disabled: disabled,
              error: errors[thisName],
              focus: isFocus,
            })}
          >
            <input
              type="text"
              placeholder={placeholder}
              {...register(thisName, {
                required: required,
                onChange: changeInput,
                onBlur: () => setIsFocus(false),
              })}
              onFocus={() => setIsFocus(true)}
            />
          </div>
          <p className="count1">
            <strong>{count}</strong> / 최대 100자
          </p>
        </>
      )}

      <p className="cell_helper"></p>
    </div>
  );
};

export default Input;
