import { useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSectionContext } from '../../../../contexts/SectionContext';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';
import classNames from 'classnames';

interface CellBoxInfoProps {
  name: string;
  verificate?: (value: string) => boolean;
  masking?: (e: any) => string;
  validateFunc?: (value: string) => boolean;
  placeholder?: string;
  errorHelper?: string;
  focusHelper?: string;
}

const CellBoxInfo: React.FC<CellBoxInfoProps> = ({
  name,
  verificate,
  masking,
  validateFunc,
  placeholder,
  errorHelper,
  focusHelper,
}) => {
  const {
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();
  const { required, disabled, isStatic } = useSectionContext();

  const [isValid, setIsValid] = useState(true);
  const [isFocus, setIsFocus] = useState(false);
  const formRef = useRef(null);

  const changeHandler = e => {
    if (masking) {
      const maskingValue = masking(e);
      setValue(name, maskingValue, { shouldValidate: true });
    }
    if (verificate) {
      const verificationResult = verificate(e.target.value);
      setIsValid(verificationResult);
    }
  };

  const deleteContents = e => {
    e.preventDefault();
    setIsFocus(false);
    setValue(name, '');
  };

  useOutsideClick(formRef, () => {
    setIsFocus(false);
  });

  if (!name) {
    return <div>name 값은 필수입니다.</div>;
  } else if (isStatic) {
    return <p className="result">{getValues(name)}</p>;
  } else {
    return (
      <div className="cell_box">
        <div
          className={classNames({
            cell_txt1: true,
            disabled: disabled,
            error: !isValid || errors[name],
            focus: isFocus,
          })}
          ref={formRef}
        >
          <input
            type="text"
            placeholder={placeholder}
            onFocus={() => setIsFocus(true)}
            {...register(name, {
              validate: validateFunc ?? (() => true),
              onChange: changeHandler,
              required: required,
            })}
            disabled={disabled}
          />
          {getValues(name) && getValues(name).length > 0 && (
            <a className="btn_clear" onClick={deleteContents}>
              내용 지우기
            </a>
          )}
        </div>
        {(!isValid || errors[name]) && (
          <p className="cell_helper1 error">{errorHelper}</p>
        )}
        {isFocus && <p className="cell_helper1">{focusHelper}</p>}
      </div>
    );
  }
};

export default CellBoxInfo;
