import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function ServiceRedirect() {
  const navigate = useNavigate();

  useEffect(() => {
    const serviceKey = localStorage.getItem('serviceKey');
    if (serviceKey) {
      navigate(`/service/${serviceKey}`);
    } else {
      navigate('/error');
    }
  }, [navigate]);

  return null; // 렌더링할 필요 없는 컴포넌트
}

export default ServiceRedirect;
