import { createContext, useContext } from 'react';

const SectionContext = createContext(null);

export const SectionProvider = ({ children, section }) => {
  return (
    <SectionContext.Provider value={section}>
      {children}
    </SectionContext.Provider>
  );
};

export const useSectionContext = () => {
  const context = useContext(SectionContext);
  if (!context) {
    throw new Error('Cannot find SectionProvider');
  }
  return context;
};
