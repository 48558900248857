function getDayName(dateInfo, isEnglish) {
  const korDays = ['일', '월', '화', '수', '목', '금', '토'];
  const engDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const days = isEnglish ? engDays : korDays;
  return days[dateInfo.getDay()];
}

function makeDate(element) {
  const thisData = element;
  if (!thisData) {
    return '';
  }
  const {
    dateInfo,
    isStartExpected,
    isEndExpected,
    isRange,
    isTime,
    isEnglish,
    isFirst,
  } = thisData;

  const dateOption: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };

  const timeOption: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    hour12: false,
    minute: '2-digit',
  };

  const thisStartDate = new Date(dateInfo.startDate)
    .toLocaleDateString('ko-KR', dateOption)
    .replace(/. /g, '.');

  const thisStartTime = isTime
    ? new Date(dateInfo.startDate).toLocaleTimeString('ko-KR', timeOption)
    : '';

  const thisStartDay = getDayName(new Date(dateInfo.startDate), isEnglish);

  const thisEndDate = isRange
    ? new Date(dateInfo.endDate)
        ?.toLocaleDateString('ko-KR', dateOption)
        .replace(/. /g, '.')
    : '';

  const thisEndTime =
    isRange && isTime
      ? new Date(dateInfo.endDate).toLocaleTimeString('ko-KR', timeOption)
      : '';

  const thisEndDay = isRange
    ? getDayName(new Date(dateInfo.endDate), isEnglish)
    : '';

  let thisDate = '';
  let startDateString = `${thisStartDate} (${thisStartDay})`;
  let endDateString = `${thisEndDate} (${thisEndDay})`;

  if (isTime) {
    startDateString = `${thisStartDate} ${thisStartTime} (${thisStartDay})`;
    endDateString = `${thisEndDate} ${thisEndTime} (${thisEndDay})`;
  }

  if (isRange) {
    thisDate = `${startDateString} - ${endDateString}`;
  } else {
    thisDate = `${startDateString} - 제한없음`;
  }

  if (isFirst) {
    thisDate = '신청 기간 없음';
  }
  return `<span>${thisDate}</span>`;
}

export default makeDate;
