import { useRecoilState } from 'recoil';
import { pageState } from '../store/pageState';
import { useNavigate, useParams } from 'react-router-dom';
import { errorToast, successToast } from '../library/makeToast';
import { rankedCheck, sendApplyEmail, submitForm } from '../api/apply';
import { usePortal } from '../contexts/PortalContext';
import { getEmailInfo, getHasKeyType } from '../model/KeyType';

const useHandleSubmit = (araPageData, configData) => {
  const [pageIdx, setPageIdx] = useRecoilState(pageState);
  const { serviceKey } = useParams();
  const navigate = useNavigate();
  const pageLength = araPageData.length;
  const { myAlert } = usePortal();

  const submitFunc = async data => {
    if (!serviceKey) return;

    // 저장 프로세스
    if (pageIdx === pageLength - 1) {
      const response = await rankedCheck(serviceKey, araPageData, data);
      const { message } = response;

      if (message === 'AlreadyFull') {
        myAlert('경고', '선택하신 선착순은 마감 되었습니다.');
        return;
      } else if (message === 'AlreadyRegistered') {
        myAlert('경고', '신청하신 정보로 선차순 접수 한 내역이 있습니다.');
        return;
      }
      const result = await submitForm(serviceKey, data);
      const hasKey = getHasKeyType(data);
      const emailInfo = getEmailInfo(data);

      if (result.status === 'success') {
        if (emailInfo && emailInfo !== '') {
          navigate(`/end/${serviceKey}`);
          successToast('제출이 완료되었습니다. 이메일을 전송합니다.');
          const response = await sendApplyEmail(
            emailInfo,
            serviceKey as string,
            configData.serviceName,
          );
          const { status } = response;
          if (status !== 200) {
            errorToast('이메일 전송에 문제가 생겼습니다.');
          }
        } else {
          navigate(`/end/${serviceKey}`, { state: { hasKey } });
          successToast('제출이 완료되었습니다.');
        }
      } else {
        errorToast('제출에 문제가 생겼습니다.');
      }
      return;
    }
    // 저장 아니면, 페이지 이동
    setPageIdx(pageIdx + 1);
    // 페이지 최상단으로 이동
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 100);
  };

  const errorFunc = error => {
    errorToast('제출에 실패했습니다. 다시 시도해주세요.');
    console.log('error!', error);
  };

  return { submitFunc, errorFunc };
};

export default useHandleSubmit;
