const TextBox = ({ html }) => {
  return (
    <div className="form_cell editor ">
      <pre
        className="ql-editor"
        dangerouslySetInnerHTML={{ __html: html }}
      ></pre>
    </div>
  );
};

export default TextBox;
