import { useEffect } from 'react';

interface AlertProps {
  title: string;
  message: string;
  onClose: () => void;
  onConfirm?: () => void;
}

const AlertLayer: React.FC<AlertProps> = ({
  title,
  message,
  onClose,
  onConfirm,
}) => {
  useEffect(() => {
    const escape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };
    document.addEventListener('keydown', escape);

    return () => {
      document.removeEventListener('keydown', escape);
    };
  });

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }
    onClose();
  };

  return (
    <div className="layer_dim">
      <div className="layer small">
        {title && (
          <div className="layer_header">
            <p className="title">{title}</p>
          </div>
        )}
        <div className="layer_cont">
          <p
            className="explain1"
            dangerouslySetInnerHTML={{ __html: message }}
          />
          <div className="btns">
            <a className="btn_M st4" onClick={onClose}>
              취소
            </a>
            <a className="btn_M st1" onClick={handleConfirm}>
              확인
            </a>
          </div>
        </div>
        <a className="close" onClick={onClose}>
          레이어 창 닫기
        </a>
      </div>
    </div>
  );
};

export default AlertLayer;
