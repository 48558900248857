import './assets/css/atomic/atomic.css';
import './assets/css/default.css';
import './assets/css/temp/quill.css';
import './assets/css/html_style_change.css';
import './assets/css/atomic/adjustsvg.css';

import { RecoilRoot } from 'recoil';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppRoutes from './routes/AppRoutes';
import { PortalProvider } from './contexts/PortalContext';
import SiteEnvLabel from './components/shared/atoms/layouts/SiteEnvLabel';

function App() {
  return (
    <RecoilRoot>
      <PortalProvider>
        <SiteEnvLabel />
        <AppRoutes />
        <ToastContainer />
      </PortalProvider>
    </RecoilRoot>
  );
}

export default App;
