export const sampleApplyDataArray = [
  {
    agree: true,
    applyName: '김민수',
    email: 'minsu.kim@example.com',
    birth: '1992.05.14',
    baseAddress: '(04175) 서울 마포구 대흥로 194 (대흥동, 삼보빌딩)',
    detailAddress: '3층 기획팀',
    gender: JSON.stringify({ index: 0, option: '남' }),
    highSchool: '서울고등학교',
    phoneNumber: '010-9876-5432',
  },
  {
    agree: true,
    applyName: '이지영',
    email: 'jiyeong.lee@example.com',
    birth: '1994.09.25',
    baseAddress: '(04587) 서울 중구 명동2가 87 (명동2가, 명동빌딩)',
    detailAddress: '5층 마케팅팀',
    gender: JSON.stringify({ index: 1, option: '여' }),
    highSchool: '한영여자고등학교',
    phoneNumber: '010-1122-3344',
  },
  {
    agree: true,
    applyName: '박준혁',
    email: 'junhyuk.park@example.com',
    birth: '1991.12.30',
    baseAddress: '(03087) 서울 종로구 대학로 10 (이화동, 대학빌딩)',
    detailAddress: '6층 디자인팀',
    gender: JSON.stringify({ index: 0, option: '남' }),
    highSchool: '용산고등학교',
    phoneNumber: '010-2233-4455',
  },
  {
    agree: true,
    applyName: '최윤서',
    email: 'yunseo.choi@example.com',
    birth: '1995.04.18',
    baseAddress: '(06289) 서울 강남구 역삼로 210 (역삼동, 강남빌딩)',
    detailAddress: '4층 인사팀',
    gender: JSON.stringify({ index: 1, option: '여' }),
    highSchool: '중앙여자고등학교',
    phoneNumber: '010-3344-5566',
  },
  {
    agree: true,
    applyName: '정하늘',
    email: 'haneul.jung@example.com',
    birth: '1993.08.02',
    baseAddress: '(08587) 서울 금천구 가산디지털1로 145 (가산동, 가산빌딩)',
    detailAddress: '8층 IT팀',
    gender: JSON.stringify({ index: 0, option: '남' }),
    highSchool: '광운고등학교',
    phoneNumber: '010-4455-6677',
  },
  {
    agree: true,
    applyName: '강동현',
    email: 'donghyun.kang@example.com',
    birth: '1994.01.15',
    baseAddress: '(07087) 서울 동작구 상도로 197 (상도동, 상도빌딩)',
    detailAddress: '10층 영업팀',
    gender: JSON.stringify({ index: 0, option: '남' }),
    highSchool: '신길고등학교',
    phoneNumber: '010-5566-7788',
  },
  {
    agree: true,
    applyName: '조수진',
    email: 'soojin.jo@example.com',
    birth: '1992.03.23',
    baseAddress: '(03175) 서울 종로구 경희궁길 34 (신문로2가, 진학기획)',
    detailAddress: '2층 개발부',
    gender: JSON.stringify({ index: 1, option: '여' }),
    highSchool: '경성고등학교',
    phoneNumber: '010-1234-5678',
  },
  {
    agree: true,
    applyName: '윤지훈',
    email: 'jihun.yoon@example.com',
    birth: '1990.07.21',
    baseAddress: '(06175) 서울 강남구 테헤란로 231 (역삼동, 세종타워)',
    detailAddress: '7층 법무팀',
    gender: JSON.stringify({ index: 0, option: '남' }),
    highSchool: '강남고등학교',
    phoneNumber: '010-6677-8899',
  },
  {
    agree: true,
    applyName: '임가은',
    email: 'gaeun.lim@example.com',
    birth: '1993.11.08',
    baseAddress: '(03087) 서울 종로구 자하문로 125 (창성동, 창성빌딩)',
    detailAddress: '9층 재무팀',
    gender: JSON.stringify({ index: 1, option: '여' }),
    highSchool: '덕성여자고등학교',
    phoneNumber: '010-7788-9900',
  },
  {
    agree: true,
    applyName: '신현우',
    email: 'hyunwoo.shin@example.com',
    birth: '1991.09.14',
    baseAddress: '(06087) 서울 강남구 논현로 176 (논현동, 논현빌딩)',
    detailAddress: '5층 홍보팀',
    gender: JSON.stringify({ index: 0, option: '남' }),
    highSchool: '세화고등학교',
    phoneNumber: '010-8899-0011',
  },
  {
    agree: true,
    applyName: '장서윤',
    email: 'seoyun.jang@example.com',
    birth: '1994.05.07',
    baseAddress: '(03487) 서울 은평구 서오릉로 225 (진관동, 진관빌딩)',
    detailAddress: '6층 교육팀',
    gender: JSON.stringify({ index: 1, option: '여' }),
    highSchool: '서라벌고등학교',
    phoneNumber: '010-9000-1122',
  },
  {
    agree: true,
    applyName: '한예진',
    email: 'yejin.han@example.com',
    birth: '1993.12.05',
    baseAddress: '(08687) 서울 금천구 벚꽃로 12 (가산동, 가산디지털빌딩)',
    detailAddress: '3층 기획팀',
    gender: JSON.stringify({ index: 1, option: '여' }),
    highSchool: '신일여자고등학교',
    phoneNumber: '010-9988-7766',
  },
  {
    agree: true,
    applyName: '홍성민',
    email: 'sungmin.hong@example.com',
    birth: '1991.11.20',
    baseAddress: '(03775) 서울 서대문구 연세로 50 (신촌동, 연세빌딩)',
    detailAddress: '2층 IT팀',
    gender: JSON.stringify({ index: 0, option: '남' }),
    highSchool: '대원고등학교',
    phoneNumber: '010-3344-2288',
  },
  {
    agree: true,
    applyName: '고은지',
    email: 'eunji.ko@example.com',
    birth: '1992.06.12',
    baseAddress: '(08287) 서울 구로구 디지털로 296 (구로동, 구로빌딩)',
    detailAddress: '4층 마케팅팀',
    gender: JSON.stringify({ index: 1, option: '여' }),
    highSchool: '성신여자고등학교',
    phoneNumber: '010-1221-3344',
  },
  {
    agree: true,
    applyName: '배지훈',
    email: 'jihoon.bae@example.com',
    birth: '1990.02.25',
    baseAddress: '(03975) 서울 마포구 월드컵북로 396 (상암동, 상암타워)',
    detailAddress: '1층 기획팀',
    gender: JSON.stringify({ index: 0, option: '남' }),
    highSchool: '용인고등학교',
    phoneNumber: '010-2234-5566',
  },
  {
    agree: true,
    applyName: '백은영',
    email: 'eunyoung.baek@example.com',
    birth: '1994.07.18',
    baseAddress: '(05387) 서울 송파구 올림픽로 435 (신천동, 신천타워)',
    detailAddress: '7층 디자인팀',
    gender: JSON.stringify({ index: 1, option: '여' }),
    highSchool: '경기여자고등학교',
    phoneNumber: '010-4455-6677',
  },
  {
    agree: true,
    applyName: '하민재',
    email: 'minjae.ha@example.com',
    birth: '1993.04.14',
    baseAddress:
      '(08687) 서울 금천구 가산디지털2로 98 (가산동, 대륭포스트타워)',
    detailAddress: '8층 IT팀',
    gender: JSON.stringify({ index: 0, option: '남' }),
    highSchool: '가락고등학교',
    phoneNumber: '010-5566-7788',
  },
  {
    agree: true,
    applyName: '엄지수',
    email: 'jisoo.um@example.com',
    birth: '1991.11.27',
    baseAddress: '(04587) 서울 중구 동호로 87 (신당동, 동호빌딩)',
    detailAddress: '5층 법무팀',
    gender: JSON.stringify({ index: 1, option: '여' }),
    highSchool: '광명여자고등학교',
    phoneNumber: '010-6677-8899',
  },
  {
    agree: true,
    applyName: '서재원',
    email: 'jaewon.seo@example.com',
    birth: '1990.06.15',
    baseAddress: '(06289) 서울 강남구 도산대로 210 (논현동, 도산타워)',
    detailAddress: '9층 영업팀',
    gender: JSON.stringify({ index: 0, option: '남' }),
    highSchool: '광양고등학교',
    phoneNumber: '010-7788-9900',
  },
  {
    agree: true,
    applyName: '권지민',
    email: 'jimin.kwon@example.com',
    birth: '1992.03.03',
    baseAddress: '(03775) 서울 서대문구 성산로 50 (성산동, 성산타워)',
    detailAddress: '6층 홍보팀',
    gender: JSON.stringify({ index: 1, option: '여' }),
    highSchool: '광주여자고등학교',
    phoneNumber: '010-8899-0011',
  },
  {
    agree: true,
    applyName: '남지우',
    email: 'jiwoo.nam@example.com',
    birth: '1994.09.09',
    baseAddress: '(03975) 서울 마포구 월드컵북로 396 (상암동, 상암타워)',
    detailAddress: '10층 교육팀',
    gender: JSON.stringify({ index: 0, option: '남' }),
    highSchool: '김포고등학교',
    phoneNumber: '010-9000-1122',
  },
  {
    agree: true,
    applyName: '문수빈',
    email: 'subin.moon@example.com',
    birth: '1991.12.12',
    baseAddress: '(04175) 서울 마포구 마포대로 25 (아현동, 마포타워)',
    detailAddress: '2층 재무팀',
    gender: JSON.stringify({ index: 1, option: '여' }),
    highSchool: '대일여자고등학교',
    phoneNumber: '010-9988-7766',
  },
  {
    agree: true,
    applyName: '송민석',
    email: 'minseok.song@example.com',
    birth: '1993.01.20',
    baseAddress: '(06087) 서울 강남구 논현로 176 (논현동, 논현빌딩)',
    detailAddress: '3층 IT팀',
    gender: JSON.stringify({ index: 0, option: '남' }),
    highSchool: '대전고등학교',
    phoneNumber: '010-3344-2288',
  },
  {
    agree: true,
    applyName: '임혜린',
    email: 'hyelin.lim@example.com',
    birth: '1992.07.16',
    baseAddress: '(08687) 서울 금천구 가산디지털1로 88 (가산동, 가산빌딩)',
    detailAddress: '4층 마케팅팀',
    gender: JSON.stringify({ index: 1, option: '여' }),
    highSchool: '대구여자고등학교',
    phoneNumber: '010-1221-3344',
  },
  {
    agree: true,
    applyName: '전서준',
    email: 'seojun.jeon@example.com',
    birth: '1990.10.28',
    baseAddress: '(03087) 서울 종로구 대학로 10 (이화동, 대학빌딩)',
    detailAddress: '1층 기획팀',
    gender: JSON.stringify({ index: 0, option: '남' }),
    highSchool: '대구고등학교',
    phoneNumber: '010-2234-5566',
  },
  {
    agree: true,
    applyName: '방수연',
    email: 'sooyeon.bang@example.com',
    birth: '1993.02.06',
    baseAddress: '(07087) 서울 동작구 상도로 197 (상도동, 상도빌딩)',
    detailAddress: '7층 디자인팀',
    gender: JSON.stringify({ index: 1, option: '여' }),
    highSchool: '고양여자고등학교',
    phoneNumber: '010-4455-6677',
  },
  {
    agree: true,
    applyName: '김나윤',
    email: 'nayun.kim@example.com',
    birth: '1991.11.19',
    baseAddress: '(06289) 서울 강남구 도산대로 210 (논현동, 도산타워)',
    detailAddress: '8층 인사팀',
    gender: JSON.stringify({ index: 1, option: '여' }),
    highSchool: '경북여자고등학교',
    phoneNumber: '010-5566-7788',
  },
  {
    agree: true,
    applyName: '이현우',
    email: 'hyunwoo.lee@example.com',
    birth: '1992.04.25',
    baseAddress: '(04175) 서울 마포구 대흥로 194 (대흥동, 삼보빌딩)',
    detailAddress: '5층 법무팀',
    gender: JSON.stringify({ index: 0, option: '남' }),
    highSchool: '경남고등학교',
    phoneNumber: '010-6677-8899',
  },
  {
    agree: true,
    applyName: '윤수빈',
    email: 'subin.yoon@example.com',
    birth: '1993.08.30',
    baseAddress: '(04587) 서울 중구 동호로 87 (신당동, 동호빌딩)',
    detailAddress: '9층 영업팀',
    gender: JSON.stringify({ index: 1, option: '여' }),
    highSchool: '광주고등학교',
    phoneNumber: '010-7788-9900',
  },
  {
    agree: true,
    applyName: '박정훈',
    email: 'junghoon.park@example.com',
    birth: '1990.05.12',
    baseAddress: '(03775) 서울 서대문구 연세로 50 (신촌동, 연세빌딩)',
    detailAddress: '2층 홍보팀',
    gender: JSON.stringify({ index: 0, option: '남' }),
    highSchool: '김천고등학교',
    phoneNumber: '010-8899-0011',
  },
  {
    agree: true,
    applyName: '최은서',
    email: 'eunseo.choi@example.com',
    birth: '1994.10.23',
    baseAddress: '(03487) 서울 은평구 서오릉로 225 (진관동, 진관빌딩)',
    detailAddress: '6층 교육팀',
    gender: JSON.stringify({ index: 1, option: '여' }),
    highSchool: '경기고등학교',
    phoneNumber: '010-9000-1122',
  },
  {
    agree: true,
    applyName: '정민수',
    email: 'minsu.jung@example.com',
    birth: '1992.12.19',
    baseAddress: '(08587) 서울 금천구 가산디지털1로 145 (가산동, 가산빌딩)',
    detailAddress: '3층 재무팀',
    gender: JSON.stringify({ index: 0, option: '남' }),
    highSchool: '고려고등학교',
    phoneNumber: '010-9988-7766',
  },
  {
    agree: true,
    applyName: '강다은',
    email: 'daeun.kang@example.com',
    birth: '1991.07.04',
    baseAddress: '(08687) 서울 금천구 벚꽃로 12 (가산동, 가산디지털빌딩)',
    detailAddress: '4층 IT팀',
    gender: JSON.stringify({ index: 1, option: '여' }),
    highSchool: '고양고등학교',
    phoneNumber: '010-3344-2288',
  },
  {
    agree: true,
    applyName: '조하늘',
    email: 'haneul.cho@example.com',
    birth: '1990.11.09',
    baseAddress: '(04175) 서울 마포구 마포대로 25 (아현동, 마포타워)',
    detailAddress: '1층 마케팅팀',
    gender: JSON.stringify({ index: 1, option: '여' }),
    highSchool: '동대문여자고등학교',
    phoneNumber: '010-1221-3344',
  },
  {
    agree: true,
    applyName: '윤재민',
    email: 'jaemin.yoon@example.com',
    birth: '1993.09.28',
    baseAddress: '(06289) 서울 강남구 역삼로 210 (역삼동, 강남빌딩)',
    detailAddress: '7층 기획팀',
    gender: JSON.stringify({ index: 0, option: '남' }),
    highSchool: '동대문고등학교',
    phoneNumber: '010-2234-5566',
  },
  {
    agree: true,
    applyName: '임예빈',
    email: 'yebin.lim@example.com',
    birth: '1994.03.19',
    baseAddress: '(04587) 서울 중구 명동2가 87 (명동2가, 명동빌딩)',
    detailAddress: '5층 디자인팀',
    gender: JSON.stringify({ index: 1, option: '여' }),
    highSchool: '동광여자고등학교',
    phoneNumber: '010-4455-6677',
  },
  {
    agree: true,
    applyName: '신동욱',
    email: 'dongwook.shin@example.com',
    birth: '1991.08.14',
    baseAddress: '(07087) 서울 동작구 상도로 197 (상도동, 상도빌딩)',
    detailAddress: '8층 인사팀',
    gender: JSON.stringify({ index: 0, option: '남' }),
    highSchool: '동구고등학교',
    phoneNumber: '010-5566-7788',
  },
  {
    agree: true,
    applyName: '장수민',
    email: 'sumin.jang@example.com',
    birth: '1990.06.21',
    baseAddress: '(06087) 서울 강남구 논현로 176 (논현동, 논현빌딩)',
    detailAddress: '6층 법무팀',
    gender: JSON.stringify({ index: 1, option: '여' }),
    highSchool: '동래여자고등학교',
    phoneNumber: '010-6677-8899',
  },
  {
    agree: true,
    applyName: '한지호',
    email: 'jiho.han@example.com',
    birth: '1992.05.16',
    baseAddress: '(08587) 서울 금천구 가산디지털1로 145 (가산동, 가산빌딩)',
    detailAddress: '9층 영업팀',
    gender: JSON.stringify({ index: 0, option: '남' }),
    highSchool: '동수고등학교',
    phoneNumber: '010-7788-9900',
  },
  {
    agree: true,
    applyName: '홍지윤',
    email: 'jiyoon.hong@example.com',
    birth: '1993.11.17',
    baseAddress:
      '(08687) 서울 금천구 가산디지털2로 98 (가산동, 대륭포스트타워)',
    detailAddress: '2층 홍보팀',
    gender: JSON.stringify({ index: 1, option: '여' }),
    highSchool: '동원여자고등학교',
    phoneNumber: '010-8899-0011',
  },
  {
    agree: true,
    applyName: '고서연',
    email: 'seoyeon.ko@example.com',
    birth: '1990.07.23',
    baseAddress: '(06175) 서울 강남구 테헤란로 231 (역삼동, 세종타워)',
    detailAddress: '5층 교육팀',
    gender: JSON.stringify({ index: 1, option: '여' }),
    highSchool: '동일여자고등학교',
    phoneNumber: '010-9000-1122',
  },
  {
    agree: true,
    applyName: '배민준',
    email: 'minjun.bae@example.com',
    birth: '1994.02.28',
    baseAddress: '(08287) 서울 구로구 디지털로 296 (구로동, 구로빌딩)',
    detailAddress: '3층 재무팀',
    gender: JSON.stringify({ index: 0, option: '남' }),
    highSchool: '동해고등학교',
    phoneNumber: '010-9988-7766',
  },
  {
    agree: true,
    applyName: '백지훈',
    email: 'jihoon.baek@example.com',
    birth: '1992.01.13',
    baseAddress: '(03087) 서울 종로구 자하문로 125 (창성동, 창성빌딩)',
    detailAddress: '4층 IT팀',
    gender: JSON.stringify({ index: 0, option: '남' }),
    highSchool: '동화고등학교',
    phoneNumber: '010-3344-2288',
  },
  {
    agree: true,
    applyName: '하은비',
    email: 'eunbi.ha@example.com',
    birth: '1991.05.29',
    baseAddress: '(04587) 서울 중구 동호로 87 (신당동, 동호빌딩)',
    detailAddress: '1층 마케팅팀',
    gender: JSON.stringify({ index: 1, option: '여' }),
    highSchool: '동화여자고등학교',
    phoneNumber: '010-1221-3344',
  },
  {
    agree: true,
    applyName: '엄동현',
    email: 'donghyun.eom@example.com',
    birth: '1993.03.08',
    baseAddress: '(04175) 서울 마포구 대흥로 194 (대흥동, 삼보빌딩)',
    detailAddress: '7층 기획팀',
    gender: JSON.stringify({ index: 0, option: '남' }),
    highSchool: '동화고등학교',
    phoneNumber: '010-2234-5566',
  },
  {
    agree: true,
    applyName: '서수진',
    email: 'soojin.seo@example.com',
    birth: '1990.10.19',
    baseAddress: '(08687) 서울 금천구 벚꽃로 12 (가산동, 가산디지털빌딩)',
    detailAddress: '8층 디자인팀',
    gender: JSON.stringify({ index: 1, option: '여' }),
    highSchool: '두레고등학교',
    phoneNumber: '010-4455-6677',
  },
  {
    agree: true,
    applyName: '권재민',
    email: 'jaemin.kwon@example.com',
    birth: '1992.02.07',
    baseAddress: '(03087) 서울 종로구 대학로 10 (이화동, 대학빌딩)',
    detailAddress: '5층 인사팀',
    gender: JSON.stringify({ index: 0, option: '남' }),
    highSchool: '두레고등학교',
    phoneNumber: '010-5566-7788',
  },
  {
    agree: true,
    applyName: '남지민',
    email: 'jimin.nam@example.com',
    birth: '1993.04.22',
    baseAddress: '(07087) 서울 동작구 상도로 197 (상도동, 상도빌딩)',
    detailAddress: '6층 법무팀',
    gender: JSON.stringify({ index: 1, option: '여' }),
    highSchool: '라온고등학교',
    phoneNumber: '010-6677-8899',
  },
  {
    agree: true,
    applyName: '문현서',
    email: 'hyunseo.moon@example.com',
    birth: '1991.09.25',
    baseAddress: '(06289) 서울 강남구 역삼로 210 (역삼동, 강남빌딩)',
    detailAddress: '9층 영업팀',
    gender: JSON.stringify({ index: 0, option: '남' }),
    highSchool: '남양고등학교',
    phoneNumber: '010-7788-9900',
  },
  {
    agree: true,
    applyName: '송지수',
    email: 'jisoo.song@example.com',
    birth: '1990.08.13',
    baseAddress: '(04175) 서울 마포구 마포대로 25 (아현동, 마포타워)',
    detailAddress: '2층 홍보팀',
    gender: JSON.stringify({ index: 1, option: '여' }),
    highSchool: '남한고등학교',
    phoneNumber: '010-8899-0011',
  },
];

export const sampleTextArray = [
  '이 제품의 주요 특징은 혁신적인 디자인과 뛰어난 성능입니다.',
  '취미로는 독서와 여행을 즐깁니다.',
  '최근에 읽은 책은 아주 흥미롭고 감동적이었습니다.',
  '가장 기억에 남는 여행지는 푸른 바다와 아름다운 해변이 있는 곳입니다.',
  '좋아하는 영화는 깊은 인상을 남기며 감동을 줍니다.',
  '직업에 대한 소개를 하자면 매우 보람찬 일을 하고 있습니다.',
  '미래에 이루고 싶은 목표는 자신의 사업을 성공적으로 운영하는 것입니다.',
  '평소 스트레스를 해소하기 위해 운동과 명상을 합니다.',
  '가장 좋아하는 음식은 신선한 재료로 만든 건강식입니다.',
  '어린 시절 가장 즐거웠던 기억은 친구들과 함께한 놀이 시간입니다.',
  '존경하는 인물은 많은 사람들에게 영감을 주는 리더입니다.',
  '좋아하는 음악 장르는 클래식이며 마음의 평화를 줍니다.',
  '가장 최근에 본 영화는 액션과 드라마가 잘 어우러진 작품입니다.',
  '이상적인 휴가는 조용한 곳에서의 힐링 시간입니다.',
  '가장 좋아하는 계절은 따뜻한 봄입니다.',
  '특별한 재능으로는 악기 연주를 꼽을 수 있습니다.',
  '가장 큰 도전 과제는 자기 자신을 극복하는 것이었습니다.',
  '인생 철학은 항상 긍정적으로 생각하는 것입니다.',
  '친구와의 기억에 남는 순간은 함께한 여행입니다.',
  '좋아하는 색깔은 파란색이며, 이는 차분함을 느끼게 합니다.',
  '가장 감명 깊게 본 공연은 뮤지컬입니다.',
  '가장 좋아하는 동물은 강아지입니다.',
  '최근에 본 감명 깊은 뉴스는 환경 보호에 관한 이야기입니다.',
  '가족은 삶에서 가장 소중한 존재입니다.',
  '자주 사용하는 앱은 일정 관리와 관련된 앱입니다.',
  '좋아하는 스포츠는 축구입니다.',
  '가장 최근에 방문한 식당은 맛있고 분위기 좋은 곳입니다.',
  '취미 생활로는 그림 그리기와 요리를 즐깁니다.',
  '평소에 즐겨 보는 TV 프로그램은 다큐멘터리입니다.',
  '자신의 성격은 친절하고 적극적입니다.',
  '가장 좋아하는 장소는 바닷가입니다.',
  '가장 좋아하는 음료는 커피입니다.',
  '어릴 적 꿈은 우주비행사가 되는 것이었습니다.',
  '하루 중 가장 좋아하는 시간은 저녁입니다.',
  '평소 즐겨 입는 스타일은 캐주얼입니다.',
  '가장 기억에 남는 책은 오래도록 여운이 남는 책입니다.',
  '가장 좋아하는 과일은 사과입니다.',
  '가장 행복한 순간은 가족과 함께하는 시간입니다.',
  '좋아하는 운동은 조깅입니다.',
  '가장 좋아하는 꽃은 장미입니다.',
  '가장 최근에 참석한 행사는 의미 있는 행사였습니다.',
  '자신의 가치관은 정직과 성실입니다.',
  '가장 좋아하는 음식점은 자주 가는 단골집입니다.',
  '가장 기억에 남는 생일은 친구들과 함께 보낸 생일입니다.',
  '좋아하는 동화는 교훈이 있는 이야기입니다.',
  '가장 좋아하는 명절은 설날입니다.',
  '좋아하는 운동 선수는 항상 최선을 다하는 선수입니다.',
  '가장 최근에 본 공연은 음악회입니다.',
  '가장 좋아하는 사계절 중 하나는 가을입니다.',
  '평소에 즐겨 하는 게임은 전략 게임입니다.',
  '가장 소중한 물건은 가족 사진입니다.',
];
