// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form .form_cont .form_cell .form_cell_cont .result {
  line-break: anywhere;
}

textarea:focus {
  outline: none;
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/atomic/atomic.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,YAAY;AACd","sourcesContent":[".form .form_cont .form_cell .form_cell_cont .result {\n  line-break: anywhere;\n}\n\ntextarea:focus {\n  outline: none;\n  border: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
