import CellBoxSelect from './CellBoxSelect';

const Radio = ({ className, children, html, ...props }) => {
  const { radioOption, isMultiple, isRanked, hasEtc } = props;
  let thisName = 'radio' + '_' + props.idx;
  if (isMultiple) thisName = 'checkbox' + '_' + props.idx;
  if (isRanked) thisName = 'rankedSelect' + '_' + props.idx;

  return (
    <CellBoxSelect
      name={thisName}
      optionList={radioOption}
      hasEtc={hasEtc}
      {...props}
    />
  );
};

export default Radio;
