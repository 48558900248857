const CheckboxCheckedM = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <rect
      x="4"
      y="4"
      width="16"
      height="16"
      rx="1"
      fill={'var(--user-500, #4F91FF)'}
      stroke={'var(--user-500, #4F91FF)'}
      strokeWidth="2"
    />
    <path
      d="M16.6665 8.66927L9.99983 15.3359L7.33317 12.6693"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CheckboxCheckedM;
