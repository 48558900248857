import { useEffect } from 'react';

const useAutoTest = callback => {
  useEffect(() => {
    const handleEvent = event => {
      if (event.altKey && event.shiftKey && event.type === 'click') {
        callback();
      }
    };

    if (process.env.REACT_APP_ENV !== 'real')
      document.addEventListener('click', handleEvent);

    return () => {
      document.removeEventListener('click', handleEvent);
    };
  }, [callback]);
};

export default useAutoTest;
