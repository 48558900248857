const Grid = props => {
  const {
    actionData,
    isColHeader,
    isRowHeader,
    isFullWidth,
    widthData,
    heightData,
  } = props;
  const headerData = actionData[0];
  const bodyData = actionData.slice(1);

  const fullWidth = widthData.reduce((acc, cur) => acc + cur, 0);

  return (
    <div className="table_view">
      <table
        className="tbl1"
        style={{
          width: isFullWidth ? '100%' : `${fullWidth}px`,
        }}
      >
        <thead>
          <tr>
            {headerData.map((cell, cellIndex) =>
              isRowHeader ? (
                <th
                  key={cellIndex}
                  dangerouslySetInnerHTML={{ __html: cell }}
                  style={{
                    width: widthData[cellIndex],
                    height: heightData[0],
                  }}
                />
              ) : (
                <td
                  key={cellIndex}
                  dangerouslySetInnerHTML={{ __html: cell }}
                  style={{
                    width: widthData[cellIndex],
                    height: heightData[0],
                  }}
                />
              ),
            )}
          </tr>
        </thead>
        <tbody>
          {bodyData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) =>
                isColHeader && cellIndex === 0 ? (
                  <th
                    key={cellIndex}
                    dangerouslySetInnerHTML={{ __html: cell }}
                    style={{
                      width: widthData[cellIndex],
                      height: heightData[rowIndex + 1],
                      wordBreak: 'break-all',
                    }}
                  />
                ) : (
                  <td
                    key={cellIndex}
                    dangerouslySetInnerHTML={{ __html: cell }}
                    style={{
                      width: widthData[cellIndex],
                      height: heightData[rowIndex + 1],
                      wordBreak: 'break-all',
                    }}
                  />
                ),
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Grid;
