import Address from '../search/Address';
import HighSchool from '../search/HighSchool';
import Birthday from './Birthday';
import Email from './Email';
import Gender from './Gender';
import Name from './Name';
import Phone from './Phone';

const infoTag = {
  applyName: Name,
  phoneNumber: Phone,
  email: Email,
  birth: Birthday,
  gender: Gender,
  highSchool: HighSchool,
  address: Address,
};

const Info = props => {
  const { infoType } = props;
  const ThisInfo = infoTag[infoType];
  return <ThisInfo {...props} />;
};

export default Info;
