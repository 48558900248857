import '../assets/css/atomic/error.css';

const ErrorPage = () => {
  return (
    <div className="user_wrap user_end">
      <main>
        <div className="error_container">
          <div className="core">
            <div className="form error_page">
              <p className="title active">존재하지 않는 페이지입니다.</p>
              <p className="explain active">
                데이터가 없거나, 네트워크 오류입니다. 관리자에게 문의해주세요.
              </p>
            </div>
          </div>
        </div>
      </main>

      <footer className="user_footer">
        <div className="core">
          <p className="copyright">
            Made with <strong>ARA</strong>
          </p>
        </div>
      </footer>
    </div>
  );
};

export default ErrorPage;
