import CellBoxInfo from './CellBoxInfo';

const Name = props => {
  const onlyString = e => {
    const validInputValue = e.target.value.replace(/[^a-zA-Zㄱ-ㅎ가-힣]/g, '');
    return validInputValue;
  };

  return (
    <CellBoxInfo
      name={props.infoType}
      masking={onlyString}
      placeholder={'이름을 입력해주세요.'}
      focusHelper={'이름은 문자만 입력 가능합니다.'}
      errorHelper={'이름을 입력해주세요.'}
    />
  );
};

export default Name;
