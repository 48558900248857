import { TagType } from '../../../model/AraObjectType';
import LeafNode from '../atoms/LeafNode';

function Child({ item }) {
  return (
    <>
      {item?.map(c => {
        const IsLeafNode = c.childs.length > 0 ? false : true;
        if (IsLeafNode) {
          return <LeafNode key={c.idx} item={c} />;
        }

        const ThisComponent = TagType[c.objectType];

        return (
          <ThisComponent {...c} key={c.idx}>
            {c.childs.length > 0 && <Child item={c.childs} />}
          </ThisComponent>
        );
      })}
    </>
  );
}

export default Child;
