const Footer: React.FC = () => {
  return (
    <footer className="user_footer">
      <div className="core">
        <p className="copyright">
          Made with <strong>ARA</strong>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
