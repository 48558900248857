import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PreviewHeader from '../components/PreviewPage/modules/PreviewHeader';
import FormHeader from '../components/FormPage/modules/FormHeader';
import Chunk from '../components/FormPage/modules/Chunk';
import { FormProvider, useForm } from 'react-hook-form';
import { errorToast, successToast } from '../library/makeToast';
import useAutoTest from '../hooks/useAutoTest';
import auotFill from '../library/autoFill';
import usePreviewLoad from '../hooks/usePreviewLoad';
import { useRecoilState } from 'recoil';
import { pageState } from '../store/pageState';
import FooterBtnArea from '../components/shared/modules/button/FooterBtnArea';
import LoadingPortal from '../components/shared/templates/layouts/LoadingPortal';
import useColorThema from '../hooks/useColorThema';
import ProgressBar from '../components/FormPage/modules/ProgressBar';

const PreviewPage = () => {
  const { serviceKey } = useParams();
  const [araPageData, setAraPageData] = useState([[]]);
  const [configData, setConfigData] = useState<any>(null);
  const { fetchPreviewData, fetchPreviewConfigData } = usePreviewLoad(
    serviceKey as string,
  );
  const [pageIdx, setPageIdx] = useRecoilState(pageState);

  useEffect(() => {
    if (serviceKey) {
      fetchPreviewData(setAraPageData);
      fetchPreviewConfigData(setConfigData);
    }
  }, [serviceKey]);

  const pageLength = araPageData.length;

  const methods = useForm();

  const submitFunc = data => {
    if (pageIdx === pageLength - 1) {
      console.log('submit!', data);
      // successToast('(미리보기 페이지 알림) 데이터를 제출합니다.');
    } else {
      setPageIdx(pageIdx + 1);
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 100);
    }
  };

  const errorFunc = error => {
    errorToast('(미리보기 페이지 알림) 제출에 실패했습니다.');
    console.log('error!', error);
  };

  useAutoTest(() => {
    auotFill(methods);
  });

  const thema = useColorThema(configData);
  if (!configData) return <LoadingPortal isLoading={true} />;

  return (
    <div className={`thema_${thema}`}>
      {araPageData.length > 1 && (
        <ProgressBar pageIdx={pageIdx} pageLength={araPageData.length} />
      )}
      <div className="user_wrap">
        <PreviewHeader />
        {pageLength > 0 && (
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(submitFunc, errorFunc)}>
              <main>
                <div
                  className="user_container"
                  style={{
                    minHeight: '100vh',
                    backgroundColor: 'var(--user-bgcolor)',
                  }}
                >
                  <div className="core">
                    <div className="form editor_area">
                      {pageIdx === 0 && configData && (
                        <FormHeader configData={configData} />
                      )}
                      <div className="form_cont">
                        {araPageData[pageIdx].map((item, idx) => {
                          return <Chunk item={item} key={idx} />;
                        })}
                      </div>
                    </div>
                  </div>
                  <FooterBtnArea
                    pageLength={araPageData.length}
                    isForm={true}
                    configData={configData}
                  />
                </div>
              </main>
            </form>
          </FormProvider>
        )}
      </div>
    </div>
  );
};

export default PreviewPage;
