import { useParams, useSearchParams } from 'react-router-dom';
import useAraLoad from '../hooks/useAraLoad';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { pageState } from '../store/pageState';
import { FormProvider, useForm } from 'react-hook-form';
import useAutoTest from '../hooks/useAutoTest';
import auotFill from '../library/autoFill';
import useHandleSubmit from '../hooks/useHandleSubmit';
import ProgressBar from '../components/FormPage/modules/ProgressBar';
import Chunk from '../components/FormPage/modules/Chunk';
import FormHeader from '../components/FormPage/modules/FormHeader';
import ApplyCheckLayer from '../components/shared/modules/layer/ApplyCheckLayer';
import FooterBtnArea from '../components/shared/modules/button/FooterBtnArea';
import LoadingPortal from '../components/shared/templates/layouts/LoadingPortal';
import useColorThema from '../hooks/useColorThema';

const FormPage = () => {
  const { serviceKey } = useParams();
  const [searchParams] = useSearchParams();
  const opened = searchParams.get('opened');

  const [araPageData, setAraPageData] = useState([[]]);
  const [configData, setConfigData] = useState<any>(null);
  const [pageIdx] = useRecoilState(pageState);
  const { fetchAraData, fetchConfigData } = useAraLoad(serviceKey as string);

  useEffect(() => {
    if (serviceKey) {
      fetchAraData(setAraPageData);
      fetchConfigData(setConfigData);
    }
  }, [serviceKey]);

  const methods = useForm();
  const { submitFunc, errorFunc } = useHandleSubmit(araPageData, configData);

  useAutoTest(() => {
    auotFill(methods);
  });

  const thema = useColorThema(configData);
  if (!configData) return <LoadingPortal isLoading={true} />;

  return (
    <div className={`thema_${thema}`}>
      {araPageData.length > 1 && (
        <ProgressBar pageIdx={pageIdx} pageLength={araPageData.length} />
      )}
      <div className="user_wrap">
        {opened && <ApplyCheckLayer data={araPageData} />}
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(submitFunc, errorFunc)}>
            <main>
              <div
                className="user_container"
                style={{
                  minHeight: '100vh',
                  backgroundColor: 'var(--user-bgcolor)',
                }}
              >
                <div className="core">
                  <div className="form editor_area">
                    {pageIdx === 0 && configData && (
                      <FormHeader configData={configData} />
                    )}
                    <div className="form_cont">
                      {araPageData[pageIdx] &&
                        araPageData[pageIdx].map((item, idx) => {
                          return <Chunk item={item} key={idx} />;
                        })}
                    </div>
                  </div>
                </div>
                <FooterBtnArea
                  pageLength={araPageData.length}
                  configData={configData}
                  isForm={true}
                />
              </div>
            </main>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default FormPage;
