import { TagType } from '../../../model/AraObjectType';

const LeafNode = ({ item }) => {
  const tagType = item.objectType;
  const Tag = TagType[tagType];

  if (!Tag) return null;
  return <Tag {...item}></Tag>;
};

export default LeafNode;
