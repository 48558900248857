import CellBoxInfo from './CellBoxInfo';

const Email = props => {
  const emailValidation = targetValue => {
    if (targetValue === '') return true;
    const isValidEmail = targetValue.includes('@') && targetValue.includes('.');
    return isValidEmail;
  };

  return (
    <CellBoxInfo
      name={props.infoType}
      placeholder={'이메일을 입력해주세요.'}
      verificate={emailValidation}
      validateFunc={emailValidation}
      errorHelper={'이메일 형식이 올바르지 않습니다.'}
      focusHelper={'이메일은 @와 .을 포함해야 합니다.'}
    />
  );
};

export default Email;
