import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import useAraLoad from '../hooks/useAraLoad';
import usePreventBack from '../hooks/usePreventBack';
import { errorToast, successToast } from '../library/makeToast';
import { sendApplyEmail } from '../api/apply';
import classNames from 'classnames';
import useColorThema from '../hooks/useColorThema';
import CircleCheck1 from '../components/shared/atoms/svgs/CircleCheck1';
import LoadingPortal from '../components/shared/templates/layouts/LoadingPortal';
import Footer from '../components/shared/atoms/layouts/Footer';

interface ConfigDataType {
  endPageTitle: string;
  endPageSubTitle: string;
  answerEditable: boolean;
  serviceName: string;
}

const EndPage = () => {
  const { serviceKey } = useParams();
  const location = useLocation();
  const { hasKey } = (location.state as any) || {};

  usePreventBack();

  const [isFocus, setIsFocus] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const [configData, setConfigData] = useState<ConfigDataType | null>(null);
  const { fetchConfigData } = useAraLoad(serviceKey as string);

  useEffect(() => {
    if (serviceKey) {
      fetchConfigData(setConfigData);
    }
  }, [serviceKey]);

  let endPageTitle = '제출이 완료되었습니다.';
  let endPageSubTitle = '소중한 답변 감사합니다.';
  if (configData) {
    endPageTitle =
      configData.endPageTitle === ''
        ? '제출이 완료되었습니다.'
        : configData.endPageTitle;

    endPageSubTitle =
      configData.endPageSubTitle === ''
        ? '소중한 답변 감사합니다.'
        : configData.endPageSubTitle;
  }

  const [showArea, setShowArea] = useState(false);

  const showRequestArea = () => {
    setShowArea(true);
  };

  const [email, setEmail] = useState('');
  const sendEmail = async () => {
    setIsDisabled(true);
    const serviceName = configData?.serviceName || '서비스';
    const response = await sendApplyEmail(
      email,
      serviceKey as string,
      serviceName,
    );
    const { status } = response;
    if (status === 200) {
      return successToast('이메일 전송이 완료되었습니다.');
    } else {
      return errorToast('이메일 전송에 실패했습니다.');
    }
  };

  const thema = useColorThema(configData);
  if (!configData) return <LoadingPortal isLoading={true} />;

  return (
    <div className={`thema_${thema}`}>
      <div className="user_wrap user_end">
        <main>
          <div
            className="user_container"
            style={{
              minHeight: '100vh',
              backgroundColor: 'var(--user-bgcolor)',
            }}
          >
            <div className="core">
              <div className="form last_page">
                <CircleCheck1 />
                <p className="title active">{endPageTitle}</p>
                <p className="explain active">{endPageSubTitle}</p>
                {hasKey && (
                  <p className="btn">
                    <button className="btn_M st5" onClick={showRequestArea}>
                      신청내역 메일 요청하기
                    </button>
                  </p>
                )}
              </div>
              {showArea && (
                <div className="form_cell">
                  <div className="form_cell_header">
                    <p className="title active">신청내역 메일 요청</p>
                    <p className="explain active">
                      이메일 주소를 입력하시면 신청내역을
                      <br />
                      조회 및 수정하실 수 있는 메일을 전송해 드립니다.
                    </p>
                  </div>
                  <div className="form_cell_cont">
                    <div className="cell_box">
                      <div
                        className={classNames({
                          cell_txt1: true,
                          medium: true,
                          focus: isFocus,
                        })}
                      >
                        <input
                          type="text"
                          placeholder="이메일을 입력해주세요."
                          value={email}
                          onFocus={e => {
                            setIsFocus(true);
                          }}
                          onBlur={e => {
                            setIsFocus(false);
                          }}
                          onChange={e => {
                            setEmail(e.target.value);
                          }}
                          onKeyDown={e => {
                            if (e.key === 'Enter') {
                              sendEmail();
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <p className="btn">
                    <button
                      className={classNames({
                        btn_L: true,
                        st3: true,
                        disabled: isDisabled,
                      })}
                      disabled={isDisabled}
                      onClick={sendEmail}
                    >
                      이메일 전송하기
                    </button>
                  </p>
                </div>
              )}
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default EndPage;
