// 암호화 키 설정
const secretKey = 'fnRHpIK2PkvBiPJZ23nsoO7OoIcb7kDn';

// AES 암호화 함수
export const encryptApplyId = (text: string) => {
  return btoa(text.toString().split('').reverse().join(''));
};

// AES 복호화 함수
export const decryptApplyId = (cipherText: string) => {
  return atob(cipherText).split('').reverse().join('');
};
