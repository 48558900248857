import { useEffect, useState } from 'react';
import {
  ColorList,
  ColorType,
  setBackgroundColor,
  setPrimaryColor,
  setThemaColor,
} from '../tools/colorThema';
import { generateBrightnessAdjustedColors } from '../tools/simliarColor';

const useColorThema = configData => {
  const [thema, setThema] = useState('user');
  useEffect(() => {
    if (!configData) return;
    if (ColorList.includes(configData.primaryColor)) {
      setThema(configData.primaryColor.toLowerCase());
      const thisPrimaryColor = ColorType[configData.primaryColor];
      setPrimaryColor(thisPrimaryColor);
    } else {
      setThema('user');
      const similiarColors = generateBrightnessAdjustedColors(
        configData.primaryColor,
      );
      setThemaColor(similiarColors);
    }
    setBackgroundColor(configData.backgroundColor);
  }, [configData]);

  return thema;
};

export default useColorThema;
