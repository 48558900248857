const CircleCheck1 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="52"
    height="52"
    viewBox="0 0 52 52"
    fill="none"
  >
    <circle cx="26" cy="26" r="26" fill={'var(--user-500, #4F91FF)'} />
    <path
      d="M15.334 26.6663L22.6673 33.9997L38.0006 19.333"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CircleCheck1;
