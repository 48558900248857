import { KeyType } from '../model/KeyType';
import { apiURL } from '../tools/url';

export const submitForm = async (serviceKey: string, data: any) => {
  const postData = {
    serviceKey: serviceKey,
    applyData: data,
  };
  const response = await fetch(`${apiURL}/front/apply/saveApplyData`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(postData),
  });
  const result = await response.json();
  return result;
};

const filteredByKey = submitData => {
  let keyValue = '';
  Object.keys(submitData).forEach(key => {
    if (KeyType.indexOf(key) > -1) {
      keyValue += submitData[key];
    }
  });
  return keyValue;
};

export const rankedCheck = async (
  serviceKey: string,
  araPageData: any,
  submitData: any,
) => {
  const rankedForm = araPageData.flat().find(item => item.childs[0].isRanked);
  if (!rankedForm) return true;

  const rankedFormData = submitData[`rankedSelect_${rankedForm.childs[0].idx}`];
  const parsedRankedFormData = JSON.parse(rankedFormData);
  const userKey = filteredByKey(submitData);

  const postData = {
    serviceKey: serviceKey,
    araIdx: rankedForm.idx,
    itemIdx: parsedRankedFormData.index,
    userKey: userKey,
  };

  const response = await fetch(`${apiURL}/front/apply/applyRanked`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(postData),
  });

  const result = await response.json();
  return result;
};

export const sendApplyEmail = async (
  email: string,
  serviceKey: string,
  serviceName: string,
) => {
  try {
    const postData = {
      email,
      serviceName,
      serviceKey,
    };

    const response = await fetch(`${apiURL}/front/apply/sendApply`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    });

    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return { status: 500, message: 'Failed' };
  }
};

export const checkPeriodByDB = async (
  dateInfo: any,
  isRange: boolean,
  isTime: boolean,
  isFirst: boolean,
) => {
  try {
    const postData = {
      dateInfo: dateInfo,
      isRange: isRange,
      isTime: isTime,
      isFirst: isFirst,
    };
    const response = await fetch(`${apiURL}/front/apply/checkPeriod`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    return { status: 'fail' };
  }
};
