import { useNavigate } from 'react-router-dom';
import { apiURL } from '../tools/url';
import { checkPeriodByDB } from '../api/apply';

const useAraLoad = (serviceKey: string) => {
  const navigate = useNavigate();
  const fetchAraData = async setAraPageData => {
    try {
      const postData = {
        serviceKey: serviceKey,
      };
      const postOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      };
      const response = await fetch(
        `${apiURL}/front/apply/getFormConfig`,
        postOptions,
      );
      const data = await response.json();
      if (data.length === 0) navigate('/error');
      const result = await mergeRankedCount(data);
      setAraPageData(result);
    } catch (error) {
      console.log(error);
      navigate('/error');
    }
  };

  const fetchConfigData = async setConfigData => {
    try {
      const response = await fetch(
        `${apiURL}/front/apply/getServiceConfig?serviceKey=${serviceKey}`,
      );
      const data = await response.json();
      const { configData, isDeleted, isStopped } = data;
      const parsedConfigData = JSON.parse(configData);
      const { servicePeriod } = parsedConfigData;
      checkPeriod(servicePeriod, isDeleted, isStopped);
      setConfigData(parsedConfigData);
    } catch (error) {
      console.log(error);
    }
  };

  const checkPeriod = async (servicePeriod, isDeleted, isStopped) => {
    if (isDeleted || isStopped) {
      navigate('/warn/period');
    }
    const { dateInfo, isRange, isTime, isFirst } = servicePeriod;
    const result = await checkPeriodByDB(dateInfo, isRange, isTime, isFirst);
    if (result.status === 'fail') {
      navigate('/warn/period');
    }
  };

  const fetchRankedCount = async (araIdx: number) => {
    try {
      const postData = {
        serviceKey: serviceKey,
        araIdx,
      };
      const postOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      };
      const response = await fetch(
        `${apiURL}/front/apply/new-getRankedCount`,
        postOptions,
      );
      const data = await response.json();
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const mergeRankedCount = async araPageData => {
    const rankedForm = araPageData
      .flat()
      .find((item: any) => item.childs[0].isRanked);

    if (rankedForm) {
      const { idx } = rankedForm;
      const data = await fetchRankedCount(idx);
      if (data.length > 0) rankedForm.childs[0].rankedCount = data;
    }

    return araPageData;
  };

  return { fetchAraData, fetchConfigData, mergeRankedCount };
};

export default useAraLoad;
