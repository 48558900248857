import { Route, Routes } from 'react-router-dom';
import PeriodWarning from '../pages/warning/PeriodWarning';

const WarnRoutes = () => {
  return (
    <Routes>
      <Route path="/period" element={<PeriodWarning />} />
    </Routes>
  );
};

export default WarnRoutes;
