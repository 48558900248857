import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import ApplyCheckName from '../../atoms/layer/ApplyCheckName';
import ApplyCheckPhoneNumber from '../../atoms/layer/ApplyCheckPhoneNumber';
import ApplyCheckEmail from '../../atoms/layer/ApplyCheckEmail';
import { getCheckApplyIdByKeyValue } from '../../../../api/check';
import { encryptApplyId } from '../../../../library/crypto';
import { errorToast } from '../../../../library/makeToast';
import { setCookie } from '../../../../library/cookie';

const keyList = ['applyName', 'email', 'phoneNumber'];

const getKeyData = data => {
  const flattedData = data.flat();
  const keyData = flattedData
    .filter(item => {
      if (
        item.formcode === 'info' &&
        keyList.includes(item.childs[0].infoType)
      ) {
        return item.childs[0].infoType;
      }
    })
    .map(item => item.childs[0].infoType);
  return keyData;
};

const ApplyCheckLayer = ({ data }) => {
  const { serviceKey } = useParams();
  const methods = useForm();
  const { watch } = methods;
  const formValues = watch();
  const isFormValid =
    Object.values(formValues).length > 0
      ? Object.values(formValues).every(
          value => value !== '' && value !== undefined,
        )
      : false;

  const closeURL = () => {
    window.location.href = `/service/${serviceKey}`;
  };

  const keyData = getKeyData(data);
  const getCheckApplyId = async data => {
    const result = await getCheckApplyIdByKeyValue(serviceKey, data);
    if (!result) {
      errorToast('신청내역을 찾을 수 없습니다.');
      return;
    }

    const encryptedData = encryptApplyId(result);
    setCookie('validtoken', encryptedData);
    window.location.href = `/C/${serviceKey}/${encryptedData}`;
  };

  // const { setValue } = methods;
  // useEffect(() => {
  //   setValue('applyName', '장지한');
  //   setValue('phoneNumber', '010-2747-6892');
  //   setValue('email', 'jihan@jinhakapply.com');
  // }, []);

  return (
    <FormProvider {...methods}>
      <div className="layer_dim">
        <div className="layer inquery">
          <div className="layer_header">
            <p className="logo">ARA</p>
            <p className="title">신청내역을 조회해 주세요</p>
          </div>
          <div className="layer_cont">
            {keyData.includes('applyName') && <ApplyCheckName />}
            {keyData.includes('phoneNumber') && <ApplyCheckPhoneNumber />}
            {keyData.includes('email') && <ApplyCheckEmail />}
          </div>
          <div className="layer_footer">
            <p className="btns row">
              <button
                className={classNames({
                  btn_XL: true,
                  st1: true,
                  disabled: !isFormValid,
                })}
                disabled={!isFormValid}
                onClick={methods.handleSubmit(getCheckApplyId)}
              >
                신청내역 조회
              </button>
            </p>
          </div>
          <a className="close" onClick={closeURL}>
            레이어 창 닫기
          </a>
        </div>
      </div>
    </FormProvider>
  );
};

export default ApplyCheckLayer;
