const LinkIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.74854 12.5778L12.2531 7.62312M6.05885 9.48078L4.93292 10.7192C4.63715 11.0445 4.40253 11.4308 4.24246 11.8558C4.08239 12.2809 4 12.7365 4 13.1965C4 13.6566 4.08239 14.1122 4.24246 14.5373C4.40253 14.9623 4.63715 15.3485 4.93292 15.6739C5.22869 15.9992 5.57982 16.2572 5.96626 16.4333C6.3527 16.6094 6.76689 16.7 7.18517 16.7C7.60346 16.7 8.01765 16.6094 8.40409 16.4333C8.79053 16.2572 9.14166 15.9992 9.43743 15.6739L10.5618 14.4354M9.43664 5.76458L10.5626 4.52614C10.8583 4.20081 11.2095 3.94275 11.5959 3.76668C11.9824 3.59062 12.3965 3.5 12.8148 3.5C13.2331 3.5 13.6473 3.59062 14.0337 3.76668C14.4202 3.94275 14.7713 4.20081 15.0671 4.52614C15.3629 4.85147 15.5975 5.23768 15.7575 5.66274C15.9176 6.0878 16 6.54338 16 7.00346C16 7.46354 15.9176 7.91912 15.7575 8.34418C15.5975 8.76924 15.3629 9.15545 15.0671 9.48078L13.9412 10.7192"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LinkIcon;
