import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';
import { useSectionContext } from '../../../../contexts/SectionContext';
import CheckS from '../../../shared/atoms/svgs/CheckS';

const Agree = props => {
  const { personalInfo, purpose, retentionPeriod } = props;
  const {
    register,
    formState: { errors },
    getValues,
    watch,
  } = useFormContext();

  const { required, isStatic } = useSectionContext();

  watch('agree');

  return (
    <div className="cell_box">
      <div className="list_attention1">
        <dl>
          <dt>수집하는 개인정보 항목</dt>
          <dd>{personalInfo}</dd>
          <dt>수집 및 이용 목적</dt>
          <dd>{purpose}</dd>
          <dt>보유 및 이용기간</dt>
          <dd>{retentionPeriod}</dd>
        </dl>
      </div>
      {isStatic ? (
        <p className="result">개인정보 수집 및 이용에 동의합니다.</p>
      ) : (
        <div className="cell_check1">
          <input
            type="checkbox"
            id="checkAgree"
            defaultChecked={getValues('agree')}
            {...register('agree', {
              required: required,
            })}
          />
          <label
            className={classNames({
              st8: errors['agree'],
            })}
            htmlFor="checkAgree"
          >
            {getValues('agree') && <CheckS />}
            개인정보 수집 및 이용에 동의합니다.
          </label>
        </div>
      )}
    </div>
  );
};

export default Agree;
