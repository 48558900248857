import { sampleApplyDataArray, sampleTextArray } from './sampleData';

const getRandomElement = arr => {
  if (arr.length === 0) {
    return { value: null };
  }
  const index = Math.floor(Math.random() * arr.length);
  return arr[index];
};

const sampleData = getRandomElement(sampleApplyDataArray);

const auotFill = methods => {
  const { getValues, setValue } = methods;
  const values = getValues();
  Object.keys(values).forEach(key => {
    if (values[key] && values[key] !== '') return;

    console.log('key', key);
    const thisType = key.split('_')[0];

    if (sampleData[thisType]) {
      setValue(key, sampleData[thisType]);
      return;
    }
    if (thisType === 'input') {
      const sampleText = getRandomElement(sampleTextArray);
      setValue(key, sampleText);
      return;
    } else if (thisType === 'radio') {
      const thisRadioElement = document.querySelectorAll(
        'input[name=' + key + ']',
      );
      const randomElement = getRandomElement(thisRadioElement);
      const randomValue = randomElement.value;
      setValue(key, randomValue);
      return;
    } else if (thisType === 'checkbox') {
      // checkbox일 경우 두개 이상 선택 가능
      const thisCheckboxElement = document.querySelectorAll(
        'input[name=' + key + ']',
      );
      const randomElement1 = getRandomElement(thisCheckboxElement);
      const randomElement2 = getRandomElement(thisCheckboxElement);
      const randomValue = [randomElement1.value, randomElement2.value];
      setValue(key, randomValue);
      return;
    } else if (thisType === 'rankedSelect') {
      const thisRankedElement = document.querySelectorAll(
        'input[name=' + key + ']',
      );
      const randomElement = getRandomElement(thisRankedElement);
      const randomValue = randomElement.value;
      setValue(key, randomValue);
      return;
    } else if (thisType === 'databaseRadio') {
      const thisDbElement = document.querySelectorAll(
        'input[name=' + key + ']',
      );
      const randomElement = getRandomElement(thisDbElement);
      const randomValue = randomElement.value;
      setValue(key, randomValue);
      return;
    } else if (thisType === 'databaseCheckBox') {
      const thisDbElement = document.querySelectorAll(
        'input[name=' + key + ']',
      );
      const randomElement1 = getRandomElement(thisDbElement);
      const randomElement2 = getRandomElement(thisDbElement);
      const randomValue = [randomElement1.value, randomElement2.value];
      setValue(key, randomValue);
      return;
    }
  });

  console.log('autoFill end', getValues());
};

export default auotFill;
