import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const usePreventBack = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname;

    const handleBack = event => {
      event.preventDefault();
      navigate(currentPath);
    };

    window.history.pushState(null, document.title, currentPath);
    window.addEventListener('popstate', handleBack);

    return () => {
      window.removeEventListener('popstate', handleBack);
    };
  }, [navigate, location.pathname]);
};

export default usePreventBack;
