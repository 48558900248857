const Div = ({ className, childs, children, html }) => {
  if (childs.length > 0) {
    return (
      <div className={className}>
        {html}
        {children}
      </div>
    );
  } else {
    return (
      <div
        className={className}
        dangerouslySetInnerHTML={{ __html: html }}
      ></div>
    );
  }
};

export default Div;
