// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cell_radio2 input[type='radio']:checked+label::before {
  display: none;
}

.cell_check2 input[type='checkbox']:checked+label::before {
  display: none;
}

.cell_check1 input[type="checkbox"]:checked+label::before {
  display: none;
}

.user_container .last_page .title {
  background-image: none !important;
  padding-top: 19px;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/atomic/adjustsvg.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,iCAAiC;EACjC,iBAAiB;AACnB","sourcesContent":[".cell_radio2 input[type='radio']:checked+label::before {\n  display: none;\n}\n\n.cell_check2 input[type='checkbox']:checked+label::before {\n  display: none;\n}\n\n.cell_check1 input[type=\"checkbox\"]:checked+label::before {\n  display: none;\n}\n\n.user_container .last_page .title {\n  background-image: none !important;\n  padding-top: 19px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
