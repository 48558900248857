const InfoIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM11 6.5C11 7.05228 10.5523 7.5 10 7.5C9.44772 7.5 9 7.05228 9 6.5C9 5.94772 9.44772 5.5 10 5.5C10.5523 5.5 11 5.94772 11 6.5ZM10 8.95703C10.5523 8.95703 11 9.40475 11 9.95703V13.457C11 14.0093 10.5523 14.457 10 14.457C9.44772 14.457 9 14.0093 9 13.457V9.95703C9 9.40475 9.44772 8.95703 10 8.95703Z"
        fill="white"
      />
    </svg>
  );
};

export default InfoIcon;
