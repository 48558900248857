import classNames from 'classnames';
import { DatabaseDataType } from './DatabaseTable';
import { useFormContext } from 'react-hook-form';
import { useSectionContext } from '../../../../contexts/SectionContext';
import RadioCheckedM from '../../../shared/atoms/svgs/RadioCheckedM';
import CheckboxCheckedM from '../../../shared/atoms/svgs/CheckboxCheckedM';

const DatabaseSelect = props => {
  const databaseData = props.actionData as DatabaseDataType;
  const { idx, isMultiple, isRanked, radioLimit } = props;
  const thisName = isRanked
    ? `rankedSelect_${idx}`
    : isMultiple
    ? `databaseCheckBox_${idx}`
    : `databaseRadio_${idx}`;
  const { register, watch, getValues } = useFormContext();
  const { required } = useSectionContext();

  watch(thisName);

  return (
    <div className="cell_box">
      {databaseData.body.map((item, index) => {
        return (
          <div
            className={classNames({
              cell_check2: isMultiple,
              cell_radio2: !isMultiple,
            })}
            key={`db_ck_${props.idx}_${index}`}
          >
            <input
              type={isMultiple ? 'checkbox' : 'radio'}
              id={`db_ck_${props.idx}_${index}`}
              value={JSON.stringify({
                index,
                option: Object.values(item).join(' '),
              })}
              defaultChecked={
                getValues(thisName) &&
                getValues(thisName)?.indexOf(
                  JSON.stringify({
                    index,
                    option: Object.values(item).join(' '),
                  }),
                ) > -1
              }
              {...register(thisName, {
                required: required,
              })}
            />
            <label
              htmlFor={`db_ck_${props.idx}_${index}`}
              className={classNames({
                disabled: false,
              })}
            >
              <Checked
                isMultiple={isMultiple}
                value={getValues(thisName)}
                item={item}
              />
              {databaseData.header.map(
                header =>
                  !header.hidden && (
                    <span
                      key={header.headerIdx}
                      dangerouslySetInnerHTML={{
                        __html: item[header.headerIdx],
                      }}
                    />
                  ),
              )}
              {isRanked && (
                <em className="count">선착순 {radioLimit[index] ?? 0}명</em>
              )}
            </label>
          </div>
        );
      })}
    </div>
  );
};

const Checked = ({ isMultiple, value, item }) => {
  const itemValue = Object.values(item).join(' ');
  if (isMultiple) {
    return (
      (value && value.some(ele => JSON.parse(ele).option === itemValue) && (
        <CheckboxCheckedM />
      )) ||
      null
    );
  } else {
    return (value && value.includes(itemValue) && <RadioCheckedM />) || null;
  }
};

export default DatabaseSelect;
