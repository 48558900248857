import { useRecoilState } from 'recoil';
import { pageState } from '../../../../store/pageState';
import FooterBtn from '../../atoms/button/FooterBtn';

interface FooterBtnProps {
  pageLength: number;
  isForm?: boolean;
  configData: any;
}

const FooterBtnArea: React.FC<FooterBtnProps> = ({
  pageLength,
  isForm = false,
  configData,
}) => {
  const [pageIdx, setPageIdx] = useRecoilState(pageState);

  const gotoPrevPage = () => {
    setPageIdx(pageIdx - 1);
  };

  const gotoNextPage = () => {
    setPageIdx(pageIdx + 1);
  };

  // 폼이 있는 경우 (preview, form 페이지)
  if (isForm) {
    // 페이지가 1개인 경우
    if (pageIdx === 0 && pageLength === 1) {
      if (configData?.hideSubmitButton) return null;
      return <FooterBtn nextBtnText="제출" />;
    }
    // 첫 페이지
    else if (pageIdx === 0) {
      return <FooterBtn nextBtnText="다음" />;
    }
    // 마지막 페이지
    else if (pageIdx === pageLength - 1) {
      if (configData?.hideSubmitButton) return <FooterBtn prevBtnText="이전" />;
      else
        return (
          <FooterBtn
            prevBtnText="이전"
            nextBtnText="제출"
            prevFunc={gotoPrevPage}
          />
        );
    }
    // 그 외엔 페이지 이동
    else {
      return (
        <FooterBtn
          prevBtnText="이전"
          nextBtnText="다음"
          prevFunc={gotoPrevPage}
        />
      );
    }
  } else {
    // 첫 페이지
    if (pageIdx === 0 && pageLength === 1) {
      return null;
    } else if (pageIdx === 0) {
      return <FooterBtn nextBtnText="다음" />;
    } else if (pageIdx === pageLength - 1) {
      return <FooterBtn prevBtnText="이전" prevFunc={gotoPrevPage} />;
    }
    // 중간 페이지
    else {
      return (
        <FooterBtn
          prevBtnText="이전"
          nextBtnText="다음"
          prevFunc={gotoPrevPage}
          nextBtnType="button"
          nextFunc={gotoNextPage}
        />
      );
    }
  }
};

export default FooterBtnArea;
