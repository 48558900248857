import { cssTransition, toast, ToastOptions } from 'react-toastify';
import '../assets/css/atomic/toast.css';
import WarningIcon from '../components/shared/atoms/icons/WarningIcon';
import InfoIcon from '../components/shared/atoms/icons/InfoIcon';
import SuccessIcon from '../components/shared/atoms/icons/SuccessIcon';
import ErrorIcon from '../components/shared/atoms/icons/ErrorIcon';
import LinkIcon from '../components/shared/atoms/icons/LinkIcon';

const EaseInOut = cssTransition({
  enter: 'EaseIn',
  exit: 'EaseOut',
});

export const toastDarkOption: ToastOptions = {
  position: 'bottom-center',
  autoClose: 1000,
  hideProgressBar: true,
  closeOnClick: true,
  progress: undefined,
  theme: 'dark',
  transition: EaseInOut,
  closeButton: false,
};

export const successToast = message => {
  toast.success(message, {
    ...toastDarkOption,
    icon: SuccessIcon,
  });
};

export const warnToast = message => {
  toast.warn(message, {
    ...toastDarkOption,
    icon: WarningIcon,
  });
};

export const infoToast = message => {
  toast.info(message, {
    ...toastDarkOption,
    icon: InfoIcon,
  });
};

export const errorToast = message => {
  toast.error(message, {
    ...toastDarkOption,
    icon: ErrorIcon,
  });
};

export const linkToast = message => {
  toast(message, {
    ...toastDarkOption,
    icon: LinkIcon,
  });
};
