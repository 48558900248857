type Header = {
  headerIdx: number;
  headerName: string;
  hidden: boolean;
};

// Body 구조 타입 정의
type Body = {
  [key: number]: string;
};

export type DatabaseDataType = {
  header: Header[];
  body: Body[];
};

const DatabaseTable = props => {
  const databaseData = props.actionData as DatabaseDataType;
  return (
    <table className="tbl1">
      <thead>
        <tr>
          {databaseData.header.map(
            (header, index) =>
              !header.hidden && (
                <th
                  key={`header-${header.headerIdx}`}
                  dangerouslySetInnerHTML={{ __html: header.headerName }}
                />
              ),
          )}
        </tr>
      </thead>
      <tbody>
        {databaseData.body.map((body, index) => (
          <tr key={index}>
            {databaseData.header.map(
              header =>
                !header.hidden && (
                  <td
                    key={`body-${header.headerIdx}-${index}`}
                    dangerouslySetInnerHTML={{ __html: body[header.headerIdx] }}
                  />
                ),
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default DatabaseTable;
