const ProgressBar = ({ pageIdx, pageLength }) => {
  return (
    <div
      className="progress"
      style={{
        zIndex: 1000,
      }}
    >
      <p>
        <span
          style={{
            width: `${((pageIdx + 1) / pageLength) * 100}%`,
          }}
        ></span>
      </p>
    </div>
  );
};

export default ProgressBar;
