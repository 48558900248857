import { useEffect, useState } from 'react';
import Form from './Form';
import Child from './Child';
import { SectionProvider } from '../../../contexts/SectionContext';

interface ChunkProps {
  item: any;
  disabled?: boolean;
  isStatic?: boolean;
}

const Chunk: React.FC<ChunkProps> = ({ item, disabled, isStatic }) => {
  const [section, setSection] = useState(item);

  useEffect(() => {
    setSection(item);
  }, [item]);

  const { required } = section;

  return (
    <SectionProvider section={{ required, disabled, isStatic }}>
      <Form {...section}>
        <Child item={section.childs}></Child>
      </Form>
    </SectionProvider>
  );
};

export default Chunk;
