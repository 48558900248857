interface BtnAreaProps {
  prevBtnText?: string;
  nextBtnText?: string;
  nextBtnType?: 'button' | 'submit';
  prevFunc?: () => void;
  nextFunc?: () => void;
}

const FooterBtn: React.FC<BtnAreaProps> = ({
  prevBtnText,
  nextBtnText,
  nextBtnType = 'submit',
  prevFunc,
  nextFunc,
}) => {
  return (
    <div className="user_container_footer">
      <p className="btns left">
        {prevBtnText && (
          <button
            type="button"
            className="btn_XL st5 ic_arrow-left_left"
            onClick={prevFunc}
          >
            {prevBtnText}
          </button>
        )}
      </p>
      <p className="btns right">
        {nextBtnText && (
          <button className="btn_XL st3" type={nextBtnType} onClick={nextFunc}>
            {nextBtnText}
          </button>
        )}
      </p>
    </div>
  );
};

export default FooterBtn;
