import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getApplyDataByApplyId } from '../api/check';
import usePreviewLoad from '../hooks/usePreviewLoad';
import { useRecoilState } from 'recoil';
import { pageState } from '../store/pageState';
import { FormProvider, useForm } from 'react-hook-form';
import { errorToast, successToast } from '../library/makeToast';
import FormHeader from '../components/FormPage/modules/FormHeader';
import Chunk from '../components/FormPage/modules/Chunk';
import { decryptApplyId } from '../library/crypto';
import FooterBtnArea from '../components/shared/modules/button/FooterBtnArea';
import { getCookie, removeCookie } from '../library/cookie';
import LoadingPortal from '../components/shared/templates/layouts/LoadingPortal';
import useColorThema from '../hooks/useColorThema';

const ConfirmPage = () => {
  const validToken = getCookie('validtoken');

  const { applyKey, serviceKey } = useParams();
  if (applyKey) {
    if (!validToken || applyKey !== validToken) {
      window.location.href = `/service/${serviceKey}?opened=true`;
    }
  }

  useEffect(() => {
    // 페이지가 닫힐 때 또는 새로고침 시 쿠키 삭제
    const handleBeforeUnload = () => {
      removeCookie('validtoken');
      console.log('페이지 닫힘 또는 새로고침 시 쿠키 삭제');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [removeCookie]);

  const navigate = useNavigate();

  const [applyData, setApplyData] = useState(null);
  const getData = async () => {
    const applyId = decryptApplyId(applyKey as string);
    const result = await getApplyDataByApplyId(applyId);
    if (!result) navigate('/error');
    setApplyData(result);
  };

  const [araPageData, setAraPageData] = useState([[]]);
  const [configData, setConfigData] = useState<any>(null);
  const { fetchPreviewData, fetchPreviewConfigData } = usePreviewLoad(
    serviceKey as string,
  );
  const [pageIdx, setPageIdx] = useRecoilState(pageState);

  useEffect(() => {
    if (serviceKey) {
      fetchPreviewData(setAraPageData);
      fetchPreviewConfigData(setConfigData);
    }
  }, [serviceKey]);

  const pageLength = araPageData.length;
  const methods = useForm();
  const submitFunc = data => {
    if (pageIdx === pageLength - 1) {
      successToast('데이터를 저장합니다.');
    } else {
      setPageIdx(pageIdx + 1);
    }
  };

  const errorFunc = error => {
    errorToast('(조회 페이지 알림) 제출에 실패했습니다.');
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (applyData) {
      methods.reset(applyData);
    }
  }, [applyData, pageIdx]);

  const [disabled, setDisabled] = useState(true);
  const [isStatic, setIsStatic] = useState(true);

  const thema = useColorThema(configData);

  if (!configData || !applyData) return <LoadingPortal isLoading={true} />;

  return (
    applyData && (
      <div className={`thema_${thema}`}>
        <div className="user_wrap user_start">
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(submitFunc, errorFunc)}>
              {pageLength > 0 && (
                <main>
                  <div
                    className="user_container"
                    style={{
                      minHeight: '100vh',
                      backgroundColor: 'var(--user-bgcolor)',
                    }}
                  >
                    <div className="core">
                      <div className="form editor_area editor_done">
                        {pageIdx === 0 && configData && (
                          <FormHeader configData={configData} />
                        )}
                        <div className="form_cont">
                          {araPageData[pageIdx].map((item, idx) => {
                            return (
                              <Chunk
                                item={item}
                                key={idx}
                                isStatic={isStatic}
                              />
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <FooterBtnArea
                      pageLength={pageLength}
                      configData={configData}
                    />
                  </div>
                </main>
              )}
            </form>
          </FormProvider>
        </div>
      </div>
    )
  );
};

export default ConfirmPage;
