import React, { createContext, useContext, useState, useCallback } from 'react';
import Portal from '../components/shared/templates/layouts/Portal';
import AlertLayer from '../components/shared/modules/layer/AlertLayer';

interface PortalContextType {
  openPortal: (content: React.ReactNode) => void;
  closePortal: () => void;
  myAlert: (title: string, message: string, callback?: () => void) => void;
}

const PortalContext = createContext<PortalContextType | null>(null);

export const PortalProvider = ({ children }) => {
  const [portalContent, setPortalContent] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const openPortal = useCallback((content: any) => {
    setPortalContent(content);
    setIsOpen(true);
  }, []);

  const closePortal = useCallback(() => {
    setPortalContent(null);
    setIsOpen(false);
  }, []);

  const myAlert = (title: string, message: string, callback?: () => void) => {
    openPortal(
      <AlertLayer
        title={title}
        message={message}
        onConfirm={callback || closePortal}
        onClose={closePortal}
      />,
    );
  };

  return (
    <PortalContext.Provider value={{ openPortal, closePortal, myAlert }}>
      {children}
      {isOpen && <Portal>{portalContent}</Portal>}
    </PortalContext.Provider>
  );
};

export const usePortal = () => {
  const context = useContext(PortalContext);
  if (!context) {
    throw new Error('usePortal must be used within a PortalProvider');
  }
  return context;
};
