import CellBoxInfo from './CellBoxInfo';

const Birthday = props => {
  const formatBirthday = value => {
    const numbers = value.replace(/[^\d]/g, '');
    let formatted = '';

    if (numbers.length > 4) {
      formatted = `${numbers.slice(0, 4)}.`;
      if (numbers.length >= 6) {
        formatted += `${numbers.slice(4, 6)}.`;
        if (numbers.length > 8) {
          formatted += numbers.slice(6, 8);
        } else {
          formatted += numbers.slice(6);
        }
      } else {
        formatted += numbers.slice(4);
      }
    } else {
      formatted = numbers;
    }

    return formatted;
  };

  //한글과 영문만 입력되게 하는 함수
  const validateBirthday = value => {
    if (value === '') return true;
    const regex = /^\d{4}\.\d{2}\.\d{2}$/; // yyyy.mm.dd 형식 체크
    return regex.test(value);
  };

  const masking = e => {
    const { value } = e.target;
    const formattedBirthday = formatBirthday(value);
    return formattedBirthday;
  };

  return (
    <CellBoxInfo
      name={props.infoType}
      masking={masking}
      verificate={validateBirthday}
      validateFunc={validateBirthday}
      placeholder={'생년월일을 입력해주세요.'}
      errorHelper={'생년월일 형식이 올바르지 않습니다.'}
      focusHelper={'생년월일은 yyyy.mm.dd 형식으로 입력해주세요.'}
    />
  );
};

export default Birthday;
