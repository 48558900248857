//cookie.js
import { Cookies } from 'react-cookie';
import { cookieDomain } from '../tools/url';

const cookies = new Cookies();

export const setCookie = (name: string, value: any, options?: Object) => {
  return cookies.set(name, value, {
    ...options,
    path: '/',
    domain: cookieDomain,
  });
};

export const getCookie = name => {
  return cookies.get(name);
};

export const removeCookie = name => {
  return cookies.remove(name, { path: '/', domain: cookieDomain });
};
