import CellBoxInfo from './CellBoxInfo';

const Phone = props => {
  const formatPhoneNumber = e => {
    const value = e.target.value;
    const number = value.replace(/[^0-9]/g, ''); // 숫자만 추출
    if (number.length < 4) return number;
    if (number.length < 7) return `${number.slice(0, 3)}-${number.slice(3)}`;
    if (number.length < 11)
      return `${number.slice(0, 3)}-${number.slice(3, 6)}-${number.slice(6)}`;
    return `${number.slice(0, 3)}-${number.slice(3, 7)}-${number.slice(7, 11)}`;
  };

  const verificateLength = value => {
    if (value === '') return true;
    return value.length === 13;
  };

  return (
    <CellBoxInfo
      name={props.infoType}
      masking={formatPhoneNumber}
      verificate={verificateLength}
      validateFunc={verificateLength}
      placeholder={'연락처를 입력해주세요.'}
      errorHelper={'연락처의 형식을 맞춰주세요'}
      focusHelper={'-를 제외한 숫자만 입력 가능합니다.'}
    />
  );
};

export default Phone;
