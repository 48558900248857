import '../../../../assets/css/atomic/loading.css';
import { createPortal } from 'react-dom';

const LoadingSpinner = () => {
  return (
    <div className="loading-container">
      <div className="loadingio-spinner-pulse">
        <div className="ldio">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      {/* <p className="loading-text">로딩중입니다...</p> */}
    </div>
  );
};

const LoadingPortal = ({ isLoading }) => {
  if (!isLoading) return null;
  return createPortal(
    <LoadingSpinner />,
    document.getElementById('root') as HTMLElement,
  );
};

export default LoadingPortal;
