const WarningIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8548 2.41139C10.4656 1.76912 9.53367 1.76912 9.14442 2.41139L0.919798 15.982C0.515895 16.6484 0.995716 17.5003 1.775 17.5003H18.2242C19.0035 17.5003 19.4833 16.6484 19.0794 15.982L10.8548 2.41139ZM8.99961 13.9573C8.99961 13.4051 9.44733 12.9573 9.99961 12.9573C10.5519 12.9573 10.9996 13.4051 10.9996 13.9573C10.9996 14.5096 10.5519 14.9573 9.99961 14.9573C9.44733 14.9573 8.99961 14.5096 8.99961 13.9573ZM9.99961 11.5003C9.44733 11.5003 8.99961 11.0526 8.99961 10.5003V8.50031C8.99961 7.94802 9.44733 7.50031 9.99961 7.50031C10.5519 7.50031 10.9996 7.94802 10.9996 8.50031L10.9996 10.5003C10.9996 11.0526 10.5519 11.5003 9.99961 11.5003Z"
      fill="#FB982E"
    />
  </svg>
);

export default WarningIcon;
