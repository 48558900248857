import { useParams } from 'react-router-dom';
import useColorThema from '../hooks/useColorThema';
import { useEffect, useState } from 'react';
import useAraLoad from '../hooks/useAraLoad';
import FormHeader from '../components/FormPage/modules/FormHeader';
import ApplyCheckName from '../components/shared/atoms/layer/ApplyCheckName';
import ApplyCheckPhoneNumber from '../components/shared/atoms/layer/ApplyCheckPhoneNumber';
import ApplyCheckEmail from '../components/shared/atoms/layer/ApplyCheckEmail';
import LoadingPortal from '../components/shared/templates/layouts/LoadingPortal';
import { getKeyData } from '../tools/getKey';
import { FormProvider, useForm } from 'react-hook-form';
import classNames from 'classnames';
import { getCheckApplyIdByKeyValue } from '../api/check';
import { errorToast } from '../library/makeToast';
import { encryptApplyId } from '../library/crypto';
import { setCookie } from '../library/cookie';
import Footer from '../components/shared/atoms/layouts/Footer';

interface ConfigDataType {
  endPageTitle: string;
  endPageSubTitle: string;
  answerEditable: boolean;
  serviceName: string;
}

const FormLoginPage = () => {
  const { serviceKey } = useParams();
  const [configData, setConfigData] = useState<ConfigDataType | null>(null);
  const [araPageData, setAraPageData] = useState([[]]);
  const { fetchAraData, fetchConfigData } = useAraLoad(serviceKey as string);
  const methods = useForm();
  const { watch } = methods;
  const formValues = watch();
  const isFormValid =
    Object.values(formValues).length > 0
      ? Object.values(formValues).every(
          value => value !== '' && value !== undefined,
        )
      : false;

  useEffect(() => {
    if (serviceKey) {
      fetchAraData(setAraPageData);
      fetchConfigData(setConfigData);
    }
  }, [serviceKey]);

  const thema = useColorThema(configData);
  const keyData = getKeyData(araPageData);
  const getCheckApplyId = async data => {
    const result = await getCheckApplyIdByKeyValue(serviceKey, data);
    if (!result) {
      errorToast('신청내역을 찾을 수 없습니다.');
      return;
    }

    const encryptedData = encryptApplyId(result);
    setCookie('validtoken', encryptedData);
    window.location.href = `/C/${serviceKey}/${encryptedData}`;
  };

  if (!configData) return <LoadingPortal isLoading={true} />;
  return (
    <div className={`thema_${thema}`}>
      <div className="user_wrap user_start">
        <FormProvider {...methods}>
          <main>
            <div className="user_container">
              <div className="core">
                <div className="form inquery">
                  <FormHeader configData={configData} />
                  <div className="form_cont">
                    {keyData.includes('applyName') && <ApplyCheckName />}
                    {keyData.includes('phoneNumber') && (
                      <ApplyCheckPhoneNumber />
                    )}
                    {keyData.includes('email') && <ApplyCheckEmail />}
                    <p className="btns row">
                      <button
                        className={classNames({
                          btn_XL: true,
                          st1: true,
                          disabled: !isFormValid,
                        })}
                        disabled={!isFormValid}
                        onClick={methods.handleSubmit(getCheckApplyId)}
                      >
                        신청내역 조회
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </FormProvider>
        <Footer />
      </div>
    </div>
  );
};

export default FormLoginPage;
