import makeDate from '../../../library/makeDate';

const FormHeader = ({ configData }) => {
  const dateString = makeDate(configData.servicePeriod);

  return (
    <div className="form_header">
      <div className="logo">
        <img src={configData.logoSrc} alt="" />
      </div>
      <div className="heading">
        <h1>
          {configData.serviceName === '' ? '제목없음' : configData.serviceName}
        </h1>
      </div>
      <div
        className="period"
        dangerouslySetInnerHTML={{ __html: dateString }}
      ></div>
    </div>
  );
};

export default FormHeader;
