import classNames from 'classnames';

// idx 속성을 포함하는 새 타입 정의
declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    idx?: number;
  }
}

const Form = props => {
  const { idx, childs, html, children, title, subtitle, formcode, required } =
    props;

  let thisFormCode = '';
  if (formcode === 'info') {
    thisFormCode = `${childs[0].infoType}_${idx}`;
  } else {
    thisFormCode = `${formcode}_${idx}`;
  }

  if (childs.length > 0) {
    return (
      <div
        className={classNames({
          form_cell: true,
          agree1: true,
        })}
        id={thisFormCode}
      >
        <div className="form_cell_header">
          <p
            className="title active"
            dangerouslySetInnerHTML={{
              __html: required ? `<em>*</em>${title}` : title,
            }}
          />
          <p className="explain active">{subtitle}</p>
        </div>
        <div className="form_cell_cont" idx={idx}>
          {html}
          {children}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default Form;
