import { useRef, useState } from 'react';
import PopupSearch from './PopupDom/PopupSearch';
import PopupDom from './PopupDom/PopupDom';
import { useFormContext } from 'react-hook-form';
import { useSectionContext } from '../../../../contexts/SectionContext';
import { useOutsideClick } from '../../../../hooks/useOutsideClick';

interface HighSchoolProps {
  idx: number;
}

const HighSchool = (props: HighSchoolProps) => {
  const { idx } = props;
  const thisHighSchoolName = `highSchool_${idx}`;
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [, setSearchResult] = useState({ NEISName: '' });
  const {
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();
  const { required, disabled, isStatic } = useSectionContext();

  const openPopup = () => {
    if (disabled) return;
    setIsFocus(true);
    setIsPopupOpen(true);
  };

  const closePopup = item => {
    setIsPopupOpen(false);
    setValue(thisHighSchoolName, item.NEISName, { shouldValidate: true });
  };

  const [isFocus, setIsFocus] = useState(false);
  const deleteContents = e => {
    e.preventDefault();
    setIsFocus(false);
    setValue(thisHighSchoolName, '');
  };

  const highSchoolRef = useRef(null);
  useOutsideClick(highSchoolRef, () => {
    setIsFocus(false);
  });

  const disabledClass = disabled ? ' disabled' : '';

  if (isStatic) {
    return <p className="result">{getValues(thisHighSchoolName)}</p>;
  }

  return (
    <div className="cell_box">
      <div
        ref={highSchoolRef}
        className={
          'cell_search1 ' +
          disabledClass +
          (isFocus ? 'focus ' : '') +
          (errors[thisHighSchoolName] ? 'error' : '')
        }
        onClick={openPopup}
      >
        <input
          type="text"
          placeholder="출신고교"
          readOnly
          {...register(thisHighSchoolName, { required: required })}
          disabled={disabled}
        />
        {isFocus && (
          <a className="btn_clear" onClick={deleteContents}>
            내용 지우기
          </a>
        )}
        <a className="btn_search">검색</a>
      </div>
      {isPopupOpen && (
        <PopupDom>
          <PopupSearch closePopup={closePopup} setResult={setSearchResult} />
        </PopupDom>
      )}
    </div>
  );
};

export default HighSchool;
