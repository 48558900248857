import React from 'react';
import WorkspaceInfo from '../atoms/WorkspaceInfo';

const PreviewHeader = () => {
  const closeWindow = () => {
    window.close();
  };

  return (
    <header className="builder_header">
      <div className="core">
        <WorkspaceInfo />
        <div className="builder_util">
          <div className="btns">
            <button className="btn_S st1" onClick={closeWindow}>
              미리보기 종료
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default React.memo(PreviewHeader);
