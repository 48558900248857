import { apiURL } from '../tools/url';

export const getApplyDataByApplyId = async applyId => {
  try {
    const response = await fetch(
      `${apiURL}/front/check/getApplyDataByApplyId?applyId=${applyId}`,
    );
    const result = await response.json();
    if (result.status === 'success') {
      return JSON.parse(result.applyData.data);
    } else return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getCheckApplyIdByKeyValue = async (serviceKey, keyValue) => {
  try {
    const postData = {
      serviceKey,
      keyValue,
    };

    const response = await fetch(`${apiURL}/front/check/getCheckApplyId`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    });

    const result = await response.json();
    if (result.status === 'success') {
      return JSON.parse(result.applyId);
    } else return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateApplyDataByApplyId = async (applyId, data) => {
  try {
    const postData = {
      applyId,
      applyData: data,
    };
    const response = await fetch(
      `${apiURL}/front/check/updateApplyDataByApplyId`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      },
    );

    const result = await response.json();
    if (result.status === 'success') {
      return true;
    } else return false;
  } catch (error) {
    console.log(error);
    return null;
  }
};
