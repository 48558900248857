export const keyList = ['applyName', 'email', 'phoneNumber'];

export const getKeyData = data => {
  const flattedData = data.flat();
  const keyData = flattedData
    .filter(item => {
      if (
        item.formcode === 'info' &&
        keyList.includes(item.childs[0].infoType)
      ) {
        return item.childs[0].infoType;
      }
    })
    .map(item => item.childs[0].infoType);
  return keyData;
};
