import { useEffect } from 'react';

export function useOutsideClick(ref, callback) {
  useEffect(() => {
    function handleClickOutside(e) {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    }
    function handleTabKey(e) {
      if (e.key === 'Tab') {
        callback();
      }
    }
    // 문서에 이벤트 리스너를 추가합니다.
    document.addEventListener('mouseup', handleClickOutside);
    document.addEventListener('keydown', handleTabKey);
    return () => {
      // 컴포넌트가 언마운트될 때 이벤트 리스너를 정리합니다.
      document.removeEventListener('mouseup', handleClickOutside);
      document.removeEventListener('keydown', handleTabKey);
    };
  }, [ref, callback]);
}

export function useMultiOutsideClick(refs, callback) {
  useEffect(() => {
    function handleClickOutside(e) {
      if (refs.every(ref => ref.current && !ref.current.contains(e.target))) {
        callback();
      }
    }
    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [refs, callback]);
}
