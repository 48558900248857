import { useFormContext } from 'react-hook-form';
import { useSectionContext } from '../../../../contexts/SectionContext';
import classNames from 'classnames';
import RadioCheckedM from '../../../shared/atoms/svgs/RadioCheckedM';
import CheckboxCheckedM from '../../../shared/atoms/svgs/CheckboxCheckedM';

const SelectOption = ({
  option,
  index,
  name,
  isMultiple,
  isRanked,
  limit,
  isDisabled,
  changeHandler,
}) => {
  const {
    register,
    getValues,
    formState: { errors },
    watch,
  } = useFormContext();

  const { required, disabled } = useSectionContext();

  watch(name);

  return (
    <div
      className={classNames({
        cell_check2: isMultiple,
        cell_radio2: !isMultiple,
        error: errors[name],
      })}
    >
      <input
        type={isMultiple ? 'checkbox' : 'radio'}
        id={name + index}
        value={JSON.stringify({ index, option })}
        defaultChecked={
          getValues(name) && getValues(name)?.indexOf(option) > -1
        }
        {...register(name, { onChange: changeHandler, required: required })}
        disabled={disabled || isDisabled}
      />
      <label
        htmlFor={name + index}
        className={classNames({
          disabled: disabled || isDisabled,
        })}
      >
        <Checked
          isMultiple={isMultiple}
          value={getValues(name)}
          item={{ index, option }}
        />
        {option}
        {isRanked && <em className="count">선착순 {limit}명</em>}
      </label>
    </div>
  );
};

const Checked = ({ isMultiple, value, item }) => {
  if (isMultiple) {
    return (
      (value &&
        Array.isArray(value) &&
        value.some(ele => JSON.parse(ele).index === item.index) && (
          <CheckboxCheckedM />
        )) ||
      null
    );
  } else {
    return (
      (value && JSON.parse(value).index === item.index && <RadioCheckedM />) ||
      null
    );
  }
};

export default SelectOption;
