const Image = ({ className, actionData }) => {
  const { src, alt } = actionData;
  return (
    <img
      className={className}
      alt={alt}
      src={src}
      style={{
        maxWidth: '100%',
        maxHeight: '100%',
        display: 'block',
        margin: 'auto',
      }}
    />
  );
};

export default Image;
