import { useFormContext } from 'react-hook-form';

const ApplyCheckPhoneNumber = () => {
  const { register } = useFormContext();
  // 연락처 마스킹 처리 함수
  const formatPhoneNumber = value => {
    const number = value.replace(/[^0-9]/g, ''); // 숫자만 추출
    if (number.length < 4) return number;
    if (number.length < 7) return `${number.slice(0, 3)}-${number.slice(3)}`;
    if (number.length < 11)
      return `${number.slice(0, 3)}-${number.slice(3, 6)}-${number.slice(6)}`;
    return `${number.slice(0, 3)}-${number.slice(3, 7)}-${number.slice(7, 11)}`;
  };
  return (
    <div className="form_cell">
      <div className="form_cell_header">
        <p className="title active">연락처</p>
      </div>
      <div className="form_cell_cont">
        <div className="cell_box">
          <div className="cell_txt1">
            <input
              type="text"
              {...register('phoneNumber', {
                onChange: e => {
                  e.target.value = formatPhoneNumber(e.target.value);
                },
              })}
              placeholder={`연락처를 입력바랍니다.`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplyCheckPhoneNumber;
