import { useFormContext } from 'react-hook-form';

const ApplyCheckName = () => {
  const { register } = useFormContext();

  return (
    <div className="form_cell">
      <div className="form_cell_header">
        <p className="title active">이름</p>
      </div>
      <div className="form_cell_cont">
        <div className="cell_box">
          <div className="cell_txt1">
            <input
              type="text"
              {...register('applyName', {
                onChange: e => {
                  e.target.value = e.target.value.replace(
                    /[^a-zA-Zㄱ-ㅎ가-힣]/g,
                    '',
                  );
                },
              })}
              placeholder={`이름을 입력바랍니다.`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplyCheckName;
