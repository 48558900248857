import { useFormContext } from 'react-hook-form';
import { useSectionContext } from '../../../../contexts/SectionContext';
import { useState } from 'react';
import classNames from 'classnames';
import CheckboxCheckedM from '../../../shared/atoms/svgs/CheckboxCheckedM';
import RadioCheckedM from '../../../shared/atoms/svgs/RadioCheckedM';

const EtcOption = ({ name, isMultiple, etcRef, changeHandler }) => {
  const {
    register,
    getValues,
    setValue,
    formState: { errors },
    watch,
  } = useFormContext();

  const { required } = useSectionContext();

  const [etcValue, setEtcValue] = useState('기타(직접입력)');
  const [isContentEditable, setIsContentEditable] = useState(false);

  watch(name);

  const editEtcValue = e => {
    const thisValues = getValues(name);
    const thisText = e.target.textContent;
    // 체크박스일 경우
    if (thisValues instanceof Array) {
      const replacedValues = thisValues.map(value => {
        if (JSON.parse(value).option === 'etc:기타(직접입력)') {
          return JSON.stringify({ index: 999, option: 'etc:' + thisText });
        } else {
          return value;
        }
      });
      setValue(name, replacedValues);
    }
    // // 라디오일 경우
    else {
      setValue(name, JSON.stringify({ index: 999, option: 'etc:' + thisText }));
    }
  };

  return (
    <div
      className={classNames({
        cell_check2: isMultiple,
        cell_radio2: !isMultiple,
        error: errors[name],
      })}
    >
      <input
        type={isMultiple ? 'checkbox' : 'radio'}
        id={name + 'etc'}
        value={JSON.stringify({ index: 999, option: 'etc:' + etcValue })}
        {...register(name, { onChange: changeHandler, required: required })}
      />
      <label id={`lbl${name}etc`} htmlFor={name + 'etc'}>
        <Checked
          isMultiple={isMultiple}
          value={getValues(name)}
          item={etcValue}
        />
        <span
          ref={etcRef}
          contentEditable={isContentEditable}
          dangerouslySetInnerHTML={{ __html: '기타(직접입력)' }}
          onClick={e => {
            setIsContentEditable(true);
          }}
          onBlurCapture={e => {
            setIsContentEditable(false);
            setEtcValue(e.target.textContent || '기타(직접입력)');
          }}
          onBlur={editEtcValue}
        />
      </label>
    </div>
  );
};

const Checked = ({ isMultiple, value, item }) => {
  if (isMultiple) {
    return (
      value &&
      Array.isArray(value) &&
      value.some(ele => JSON.parse(ele).option.indexOf(item) > -1) && (
        <CheckboxCheckedM />
      )
    );
  } else {
    return (
      value && JSON.parse(value).option.indexOf(item) > -1 && <RadioCheckedM />
    );
  }
};

export default EtcOption;
