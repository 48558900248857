const SuccessIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM14.2071 8.70711C14.5976 8.31658 14.5976 7.68342 14.2071 7.29289C13.8166 6.90237 13.1834 6.90237 12.7929 7.29289L9 11.0858L7.20711 9.29289C6.81658 8.90237 6.18342 8.90237 5.79289 9.29289C5.40237 9.68342 5.40237 10.3166 5.79289 10.7071L8.29289 13.2071C8.68342 13.5976 9.31658 13.5976 9.70711 13.2071L14.2071 8.70711Z"
      fill="#65B168"
    />
  </svg>
);

export default SuccessIcon;
