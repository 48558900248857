import Div from '../components/FormPage/atoms/tag/Div';
import Input from '../components/FormPage/atoms/form/Input';
import Form from '../components/FormPage/modules/Form';
import Image from '../components/FormPage/atoms/tag/Image';
import TextBox from '../components/FormPage/atoms/form/TextBox';
import Agree from '../components/FormPage/atoms/info/Agree';
import DatabaseTable from '../components/FormPage/atoms/table/DatabaseTable';
import Info from '../components/FormPage/atoms/info/Info';
import Radio from '../components/FormPage/atoms/select/Radio';
import DatabaseSelect from '../components/FormPage/atoms/table/DatabaseSelect';
import Grid from '../components/FormPage/atoms/table/Grid';

const AraObjectType = {
  Document: 'Document',
  Div: 'Div',
  Img: 'Img',
  Input: 'Input',
  Radio: 'Radio',
  Checkbox: 'Checkbox',
  Form: 'Form',
  Grid: 'Grid',
  Textbox: 'Textbox',
  Database: 'Database',
  DatabaseTable: 'DatabaseTable',
  DatabaseSelect: 'DatabaseSelect',
  DatabaseMaker: 'DatabaseMaker',
  Info: 'infoSelect',
  Agree: 'Agree',
  TR: 'TR',
  TD: 'TD',
  TH: 'TH',
  Tbody: 'Tbody',
};

export const TagType = {
  [AraObjectType.Img]: Image,
  [AraObjectType.Input]: Input,
  [AraObjectType.Radio]: Radio,
  [AraObjectType.Checkbox]: Radio,
  [AraObjectType.Form]: Form,
  [AraObjectType.Grid]: Grid,
  [AraObjectType.DatabaseTable]: DatabaseTable,
  [AraObjectType.DatabaseSelect]: DatabaseSelect,
  [AraObjectType.DatabaseMaker]: Div,
  [AraObjectType.Textbox]: TextBox,
  [AraObjectType.Info]: Info,
  [AraObjectType.Agree]: Agree,
};

export default AraObjectType;
