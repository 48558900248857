type Environment = 'real' | 'alpha' | 'dev' | 'local';

interface EnvironmentConfig {
  apiURL: string;
  formbuilderURL: string;
  frontURL: string;
  cookieDomain: string;
  masterURL: string;
}

const environmentConfigs: Record<Environment, EnvironmentConfig> = {
  real: {
    apiURL: 'https://ara-back.apply.kr',
    formbuilderURL: 'https://ara-gen.apply.kr',
    frontURL: 'https://apply.kr',
    cookieDomain: '.apply.kr',
    masterURL: 'https://ara.apply.kr',
  },
  alpha: {
    apiURL: 'https://ara-test-back.apply.kr',
    formbuilderURL: 'https://ara-test-gen.apply.kr',
    frontURL: 'https://atest.apply.kr',
    cookieDomain: '.apply.kr',
    masterURL: 'https://ara-test.apply.kr',
  },
  dev: {
    // dev 는 api 만 alpha 를 바라봄 그 외에는 local로 고정
    apiURL: 'https://ara-test-back.apply.kr',
    formbuilderURL: 'http://localhost:3030',
    frontURL: 'http://localhost:3040',
    cookieDomain: 'localhost',
    masterURL: 'http://localhost:3000',
  },
  local: {
    apiURL: 'http://localhost:3003',
    formbuilderURL: 'http://localhost:3030',
    frontURL: 'http://localhost:3040',
    cookieDomain: 'localhost',
    masterURL: 'http://localhost:3000',
  },
};

const env = (process.env.REACT_APP_ENV as Environment) || 'dev';
console.log(`${env} 환경으로 실행합니다.`);

const { apiURL, formbuilderURL, frontURL, cookieDomain, masterURL } =
  environmentConfigs[env];

export { apiURL, formbuilderURL, frontURL, cookieDomain, masterURL };
