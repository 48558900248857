import { errorToast } from '../../../library/makeToast';
import { masterURL } from '../../../tools/url';

const WorkspaceInfo = () => {
  const gotoServiceList = () => {
    // if (!thisWorkspace) {
    //   errorToast('해당하는 워크스페이스 정보가 없습니다.');
    //   return;
    // }
    // window.location.href = `${masterURL}/workspace/form/${thisWorkspace.idx}`;
  };

  return (
    <div
      className="builder_title"
      style={{ cursor: 'pointer' }}
      onClick={gotoServiceList}
    >
      <strong>ARA</strong>
      <span className="subject">
        <span className="empty">미리보기</span>
      </span>
    </div>
  );
};

export default WorkspaceInfo;
