import { useNavigate } from 'react-router-dom';
import { apiURL } from '../tools/url';

const usePreviewLoad = (serviceKey: string) => {
  const navigate = useNavigate();
  const fetchPreviewData = async setAraPageData => {
    try {
      const postData = {
        serviceKey: serviceKey,
      };
      const postOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      };
      const response = await fetch(`${apiURL}/form/load`, postOptions);
      const data = await response.json();
      if (data.length === 0) navigate('/error');
      setAraPageData(data);
    } catch (error) {
      console.log(error);
      navigate('/error');
    }
  };

  const fetchPreviewConfigData = async setConfigData => {
    try {
      const response = await fetch(
        `${apiURL}/form/getServiceInfo?serviceKey=${serviceKey}`,
      );
      const data = await response.json();
      setConfigData(data);
    } catch (error) {
      console.log(error);
    }
  };

  return { fetchPreviewData, fetchPreviewConfigData };
};

export default usePreviewLoad;
