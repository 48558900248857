import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import ServiceRedirect from '../pages/ServiceRedirect';
import FormPage from '../pages/FormPage';
import PreviewPage from '../pages/PreviewPage';
import EndPage from '../pages/EndPage';
import ErrorPage from '../pages/ErrorPage';
import ConfirmPage from '../pages/ConfirmPage';
import WarnRoutes from './WarnRoutes';
import FormLoginPage from '../pages/FormLoginPage';

const AppRoutes = () => {
  return (
    <BrowserRouter
      future={{ v7_startTransition: true, v7_relativeSplatPath: true }}
    >
      <Routes>
        <Route path="/service/" element={<ServiceRedirect />} />
        <Route path="/service/:serviceKey" element={<FormPage />} />
        <Route path="/userlogin/:serviceKey" element={<FormLoginPage />} />
        <Route path="/preview/:serviceKey" element={<PreviewPage />} />
        <Route path="/C/:serviceKey/:applyKey" element={<ConfirmPage />} />
        <Route path="/end/:serviceKey" element={<EndPage />} />
        <Route path="*" element={<Navigate replace to="/error" />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route path="/warn/*" element={<WarnRoutes />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
