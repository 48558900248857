// RGB -> HSL 변환 함수
export const rgbToHsl = (r: number, g: number, b: number) => {
  r /= 255;
  g /= 255;
  b /= 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h = 0,
    s = 0,
    l = (max + min) / 2;

  if (max !== min) {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }

    h /= 6;
  }

  return [h * 360, s, l]; // Hue는 360도 기준, Saturation/Lightness는 0~1 범위
};

// HSL -> RGB 변환 함수
export const hslToRgb = (h: number, s: number, l: number) => {
  let r, g, b;

  const hue2rgb = (p: number, q: number, t: number) => {
    if (t < 0) t += 1;
    if (t > 1) t -= 1;
    if (t < 1 / 6) return p + (q - p) * 6 * t;
    if (t < 1 / 3) return q;
    if (t < 1 / 2) return p + (q - p) * (2 / 3 - t) * 6;
    return p;
  };

  h /= 360;

  if (s === 0) {
    r = g = b = l; // 무채색
  } else {
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
};

// RGB 값을 HEX로 변환하는 함수
export const rgbToHex = (r: number, g: number, b: number) => {
  return '#' + [r, g, b].map(x => x.toString(16).padStart(2, '0')).join('');
};
// 알파 값을 포함한 RGBA를 HEX로 변환하는 함수
const rgbaToHex = (r: number, g: number, b: number, a: number) => {
  // RGB 값이 0~255, 알파 값이 0~1 범위를 벗어나지 않도록 검증
  const clamp = (num: number, min: number, max: number) =>
    Math.min(Math.max(num, min), max);

  const toHex = (num: number) => {
    const hex = num.toString(16).padStart(2, '0'); // 16진수로 변환하고 2자리로 맞춤
    return hex.length === 1 ? '0' + hex : hex;
  };

  r = clamp(r, 0, 255);
  g = clamp(g, 0, 255);
  b = clamp(b, 0, 255);
  a = clamp(a, 0, 1);

  const alphaHex = Math.round(a * 255)
    .toString(16)
    .padStart(2, '0'); // 알파 값을 2자리 16진수로 변환

  return `#${toHex(r)}${toHex(g)}${toHex(b)}${alphaHex}`;
};

// 색 테마 생성
export const generateBrightnessAdjustedColors = (
  baseColor: string,
  setCount: number = 5,
) => {
  // HEX -> RGB 변환
  const r = parseInt(baseColor.slice(1, 3), 16);
  const g = parseInt(baseColor.slice(3, 5), 16);
  const b = parseInt(baseColor.slice(5, 7), 16);

  // RGB -> HSL 변환
  const [h, s, l] = rgbToHsl(r, g, b);

  const colorSets = <any[]>[];

  if (l >= 0.7) {
    // 밝은 색상인 경우: 어두운 색상이 아닌 밝은 색상에서 알파 값 추가
    for (let i = 0; i < setCount; i++) {
      const lightnessOffset = i * 0.05; // 명도를 점진적으로 더 올림
      const alpha = 1 - i * 0.45; // 투명도 설정 (alpha 값 조정)
      const lighterHsl = [h, s, Math.min(l + lightnessOffset, 1)];

      // HSL -> RGB -> RGBA 변환
      const [lighterR, lighterG, lighterB] = hslToRgb(
        lighterHsl[0],
        lighterHsl[1],
        lighterHsl[2],
      );

      // RGBA -> HEX 변환
      const lighterColorWithAlpha = rgbaToHex(
        lighterR,
        lighterG,
        lighterB,
        alpha,
      );
      colorSets.push(lighterColorWithAlpha);
    }
  } else if (l <= 0.3) {
    // 어두운 색상인 경우: 밝은 색상 5개로 세트 생성 (두 개는 알파 추가)
    for (let i = 0; i < setCount; i++) {
      const lightnessOffset = i * 0.1;
      const alpha = i < 2 ? 1 - i * 0.45 : 1; // 처음 두 개는 알파 값 추가
      const lighterHsl = [h, s, Math.min(l + lightnessOffset, 1)];

      const [lighterR, lighterG, lighterB] = hslToRgb(
        lighterHsl[0],
        lighterHsl[1],
        lighterHsl[2],
      );

      const lighterColorWithAlpha = rgbaToHex(
        lighterR,
        lighterG,
        lighterB,
        alpha,
      );
      colorSets.push(lighterColorWithAlpha);
    }
  } else {
    // 중간 색상인 경우: 위 2개는 알파 추가, 아래 2개는 일반적으로 처리
    for (let i = -2; i <= 2; i++) {
      const lightnessOffset = i * 0.1;
      const alpha = i > 0 && i <= 2 ? 1 - i * 0.45 : 1; // 밝아지는 두 개는 알파 값 추가
      const adjustedHsl = [h, s, Math.min(Math.max(l + lightnessOffset, 0), 1)];

      const [adjustedR, adjustedG, adjustedB] = hslToRgb(
        adjustedHsl[0],
        adjustedHsl[1],
        adjustedHsl[2],
      );

      const adjustedColorWithAlpha = rgbaToHex(
        adjustedR,
        adjustedG,
        adjustedB,
        alpha,
      );
      colorSets.push(adjustedColorWithAlpha);
    }
  }
  return colorSets;
};

// 배경색 테마 생성
export const generateSubtleColorVariations = (
  baseColor: string,
  setCount: number = 5,
) => {
  // HEX -> RGB 변환
  const r = parseInt(baseColor.slice(1, 3), 16);
  const g = parseInt(baseColor.slice(3, 5), 16);
  const b = parseInt(baseColor.slice(5, 7), 16);

  // RGB -> HSL 변환
  const [h, s, l] = rgbToHsl(r, g, b);

  const colorSets = <any[]>[];

  // 미세한 색상 변화를 위한 루프
  for (let i = 0; i < setCount; i++) {
    const hueOffset = i * 1; // 색조(Hue)를 미세하게 변경
    const saturationOffset = i * 0.01; // 채도(Saturation)를 약간 변경
    const lightnessOffset = i * 0.05; // 명도를 약간만 변경
    const alpha = 0.2 - i * 0.05; // Alpha 값을 0.5 ~ 0.7 범위에서 설정

    // HSL에서 미세한 변화를 적용
    const adjustedHsl = [
      (h + hueOffset) % 360, // 색조는 360도를 넘지 않도록 보정
      Math.min(s + saturationOffset, 1), // 채도가 1을 넘지 않도록 제한
      Math.min(Math.max(l + lightnessOffset - 0.2, 0.2), 0.8), // 명도를 0.2 ~ 0.8 사이로 제한
    ];

    // HSL -> RGB 변환
    const [adjustedR, adjustedG, adjustedB] = hslToRgb(
      adjustedHsl[0],
      adjustedHsl[1],
      adjustedHsl[2],
    );

    // RGBA -> HEX 변환
    const adjustedColorWithAlpha = rgbaToHex(
      adjustedR,
      adjustedG,
      adjustedB,
      alpha,
    );

    // 색상 배열에 추가
    colorSets.push(adjustedColorWithAlpha);
  }

  return colorSets;
};
